import dom from "../helpers/dom";

export default class ListPeek {
  constructor(element) {
    // Configure default attributes
    const peeks = [...element.querySelectorAll("[data-peek]")];
    const triggers = [...element.querySelectorAll("[data-peek-trigger]")];
    this.activeClass = "active";

    this.revertTimer = null;
    this.revertTime = 5000;

    this.bindHovers(peeks, triggers);
  }

  clearActive(peeks) {
    peeks.forEach(peek => {
      dom.removeClass(peek, this.activeClass);
    });
  }

  setActive = (id, peeks) => {
    // Clear all active states
    this.clearActive(peeks);

    // Set active state for matching peek
    const active = peeks.filter(peek => {
      return peek.dataset.peek === id;
    });

    // Only use the first result (there should only ever be one)
    dom.addClass(active[0], this.activeClass);
  };

  bindHovers(peeks, triggers) {
    triggers.forEach(trigger => {
      trigger.addEventListener("mouseenter", () => {
        clearTimeout(this.revertTimer);
        this.setActive(trigger.dataset.peekTrigger, peeks);
      });

      trigger.addEventListener("mouseleave", () => {
        this.revertTimer = setTimeout(() => {
          this.clearActive(peeks);

          // Set default peek to active if it exists
          this.setActive("default", peeks);
          clearTimeout(this.revertTimer);
        }, this.revertTime);
      });
    });
  }
}
