import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import ScrollLocking from "../../higherOrder/ScrollLocking";

import MediaGroup from "./MediaGroup";

export default class ArtworkFiltersMedia extends PureComponent {
  static displayName = "Artwork.Filters.Media";

  static propTypes = {
    media: PropTypes.array,
    selections: PropTypes.array,
    handleMediaChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func
  };

  constructor() {
    super();
    this.state = {
      open: false,
      dirty: false
    };
  }

  onMediaChange = (id, action) => {
    this.setState({ dirty: true });
    this.props.handleMediaChange(id, action);
  };

  close = () => {
    this.setState({
      open: false
    });

    window.removeEventListener("keyup", this.handleEscClose);
  };

  handleEscClose = event => {
    if (event.keyCode === 27) {
      this.close();
    }
  };

  handleOpen = event => {
    event.preventDefault();

    this.setState({
      open: true
    });

    window.addEventListener("keyup", this.handleEscClose);
  };

  handleClear = event => {
    this.setState(
      {
        dirty: true
      },
      () => {
        this.props.onReset();
      }
    );
  };

  handleReset = event => {
    this.setState(
      {
        dirty: false
      },
      () => {
        this.props.onReset(true);
      }
    );
  };

  handleApply = event => {
    if (!this.state.dirty) {
      event.preventDefault();
      return false;
    } else {
      this.setState(
        {
          dirty: false
        },
        () => {
          this.props.onSubmit();
          this.close();
        }
      );
    }
  };

  buildButtonLabel(selections) {
    let labelText = "all";
    if (selections && selections.length > 0) {
      labelText = selections.length + " selected";
    }

    return labelText;
  }

  render() {
    const open = this.state.open;
    const overlayClass = classNames("overlay-secondary", { open });
    const buttonClass = classNames("button", {
      disabled: !this.state.dirty
    });
    const { media, selections } = this.props;
    const count =
      selections && selections.length > 0 ? ` ${selections.length}` : "";

    return (
      <div key="media" className="overlay-menu media">
        <div className="resettable">
          <button key="media" onClick={this.handleOpen}>
            <label>{"Medium: "}</label>
            <span>{this.buildButtonLabel(selections)}</span>
          </button>

          {selections && selections.length > 0 ? (
            <span
              className="reset"
              onClick={this.handleReset}
              role="button"
              tabIndex="0"
            >
              {"reset"}
            </span>
          ) : (
            <i className="icon hamburger" />
          )}
        </div>
        {selections && selections.length > 0 ? (
          <span
            className="reset under"
            onClick={this.handleReset}
            role="button"
            tabIndex="0"
          >
            {"reset"}
          </span>
        ) : null}
        <CSSTransition in={open} timeout={1000} classNames={"fade"}>
          <div>
            {open ? (
              <ScrollLocking>
                <div className={overlayClass}>
                  <div className="container">
                    <header className="header">
                      <h4 className="title">{"Select Media"}</h4>
                      <div className="utility">
                        <button
                          className={buttonClass}
                          onClick={this.handleApply}
                          disabled={!this.state.dirty}
                        >
                          {"Apply" + count}
                        </button>
                        <button
                          className="button-bare"
                          onClick={this.handleClear}
                        >
                          {"Clear"}
                        </button>
                        <button
                          className="button-close"
                          onClick={this.close}
                          aria-label="Close"
                        />
                      </div>
                    </header>
                    <div className="filters-list">
                      {media.map(group => {
                        return (
                          <MediaGroup
                            group={group}
                            selected={this.props.selections || []}
                            onMediaChange={this.onMediaChange}
                            key={group.id}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </ScrollLocking>
            ) : null}
          </div>
        </CSSTransition>
      </div>
    );
  }
}
