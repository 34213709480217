import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import MaskedInput from "react-text-mask";

export default class ArtworkFiltersYears extends PureComponent {
  static displayName = "Artwork.Filters.Years";

  static propTypes = {
    years: PropTypes.object,
    selected: PropTypes.string,
    filtered: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func
  };

  constructor() {
    super();

    const thisYear = new Date().getFullYear();
    this.state = {
      dirty: false,
      input: "",
      placeholder: `1937-${thisYear}`,
    };
  }

  componentDidMount() {
    this.setPlaceholderFromProps(this.props);
    this.inputEl = get(this.input, "inputElement");
  }

  componentDidUpdate(props, state) {
    // Only replace placeholder if there hasn't been any input
    if (!this.state.dirty && this.props.selected !== props.selected) {
      this.setPlaceholderFromProps(props, true);
    }

    // If a filtered year comes in from the form, overwrite the current input
    if (
      !this.state.dirty &&
      this.props.filtered &&
      this.props.filtered !== props.filtered
    ) {
      this.setInputFromProps(this.props);
    }
  }

  setPlaceholderFromProps(props, selectedOnly) {
    const years = props.years;

    if (props.selected) {
      this.setState({
        placeholder: this.validateYears(props.selected)
      });
    } else if (years && !selectedOnly) {
      this.setState({
        placeholder: years.min + "-" + years.max
      });
    }
  }

  setInputFromProps(props) {
    this.setState({
      input: props.filtered
    });

    this.inputEl.value = props.filtered;
  }

  validateYear(year, min, max) {
    if (year.length < 4) return "";
    return Math.max(min, Math.min(max, year));
  }

  validateYears(input) {
    let valid = "";
    const { min, max } = this.props.years;
    const years = input.split("-");

    // Nothing has been entered
    if (years[0].length <= 0) return null;
    // Validate both years (if one is entered, split will return 2 values)
    const a = this.validateYear(years[0], min, max);
    const b = years[1] ? this.validateYear(years[1], min, max) : "";

    if (b.length < 4) {
      // Only one year was valid
      valid = a + "";
    } else {
      // If two years are entered, sort them and add back the dash
      valid = [a, b].sort().join("-");
    }

    return valid;
  }

  focusInput = event => {
    this.inputEl.focus();
  };

  updateInput = event => {
    // Get the input raw number
    const inputRaw = event.target.value.replace(/\s/g, "");

    this.setState({
      dirty: true,
      input: inputRaw
    });

    if (this.props.onChange) {
      this.props.onChange(this.validateYears(inputRaw));
    }
  };

  handleEnterSubmit = event => {
    if (this.props.onEnter && this.state.dirty && event.keyCode === 13) {
      this.props.onEnter();
    }
  };

  render() {
    const activeMask =
      this.state.input.length > 4
        ? [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
        : [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];

    const placeholder = this.state.placeholder;

    return (
      <div className="years">
        <div
          className="input-range"
          onClick={this.focusInput}
          role="button"
          tabIndex="0"
        >
          <div className="left">
            <label htmlFor="searchYearsInput" id="searchYears">
              Years:
            </label>
            <div className="input-wrapper">
              <MaskedInput
                className="placeholder"
                disabled
                guide={this.state.input.length > 0}
                type="text"
                mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                placeholderChar={"Y"}
                value={this.state.input}
                aria-labelledby="searchYears"
              />
              <MaskedInput
                id="searchYearsInput"
                ref={i => {
                  this.input = i;
                }}
                className="active"
                type="text"
                guide={false}
                mask={activeMask}
                onChange={this.updateInput}
                onKeyUp={this.handleEnterSubmit}
                placeholderChar={"Y"}
                placeholder={placeholder}
                aria-labelledby="searchYears"
              />
            </div>
          </div>
          <div className="note">{"year or range"}</div>
        </div>

        <div className="note under">{"year or range"}</div>
      </div>
    );
  }
}
