import Cookies from "js-cookie";
import dom from "../helpers/dom";

import get from "lodash/get";

export default class KioskAware {
  constructor(el, props) {
    // Only check for kiosk if section is set
    if (get(props, "section")) {
      this.cooKey = `kiosk_${props.section}`;

      const enabledClass = get(props, "enabled") ? get(props, "enabled") : null;
      const disabledClass = get(props, "disabled")
        ? get(props, "disabled")
        : null;

      if (Cookies.get(this.cooKey)) {
        if (disabledClass) dom.removeClass(el, disabledClass);
        if (enabledClass) dom.addClass(el, enabledClass);
      } else {
        if (enabledClass) dom.removeClass(el, enabledClass);
        if (disabledClass) dom.addClass(el, disabledClass);
      }
    }
  }
}
