import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import queryString from "query-string";

import { ThumbnailList } from "../shared";

export default class SeriesWrapper extends PureComponent {
  static displayName = "Series.Wrapper";

  static propTypes = {
    series: PropTypes.array,
    itemTitle: PropTypes.string,
    searchPrompt: PropTypes.string,
    clearRoute: PropTypes.string
  };

  constructor() {
    super();

    this.state = {
      search: "",
      sort: ""
    };
  }

  componentDidMount() {
    // Get query parameters and pass them to the header
    this.setStateFromRoute();
  }

  setStateFromRoute() {
    const location = window.location;
    const query = queryString.parse(location.search);
    this.setState({
      search: query.search,
      sort: query.sort
    });
  }

  render() {
    if (!this.props.series) return null;

    return (
      <div>
        <Header
          search={this.state.search}
          searchPrompt={this.props.searchPrompt}
          sort={this.state.sort}
        />
        <div className="thumbnail-list">
          <ThumbnailList
            itemTitle={this.props.itemTitle}
            items={this.props.series}
            jumboThreshold={[1, 1]}
            clearRoute={this.props.clearRoute}
            noResults={this.props.series.length === 0}
          />
          {this.state.loading ? (
            <div className="loader-bar">
              <div className="progress" />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
