import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import ScrollLocking from "../higherOrder/ScrollLocking";

import Detail from "../shared/ItemDetail";

class Active extends PureComponent {
  static displayName = "Content.Active";

  static propTypes = {
    return: PropTypes.string,
    kioskSection: PropTypes.string,
    active: PropTypes.bool,
    items: PropTypes.array,
    dispatch: PropTypes.func
  };

  static mapStateToProps(state) {
    return {
      items: state.artwork.available,
      active: state.artwork.active
    };
  }

  constructor() {
    super();
    this.state = {
      activeItem: null,
      index: null
    };
  }

  componentDidMount() {
    this.setActiveFromProps(this.props.active);
  }

  componentDidUpdate(props) {
    if (this.props.active !== props.active) {
      this.setActiveFromProps(this.props.active);
    }
  }

  setActiveFromProps(id) {
    let matchIndex = null;

    const matches = this.props.items.filter((item, index) => {
      if (item.id === id) {
        matchIndex = index;
        return true;
      }
    });

    this.setState({
      activeItem: matches.length > 0 ? matches[0] : null,
      index: matchIndex
    });
  }

  prev = () => {
    const current = this.state.index;
    // Ensure prev can happen
    if (current > 0) {
      const next = this.props.items[current - 1];
      this.setActive(next.id);
    }
  };

  next = () => {
    const current = this.state.index;
    // Ensure prev can happen
    if (current + 2 <= this.props.items.length) {
      const next = this.props.items[current + 1];
      this.setActive(next.id);
    }
  };

  setActive(id) {
    this.props.dispatch({ type: "SET_ACTIVE", id });
  }

  removeActive = () => {
    const id = null;
    this.props.dispatch({ type: "SET_ACTIVE", id });
  };

  render() {
    const activeItem = this.state.activeItem;

    return (
      <CSSTransition
        in={activeItem !== null}
        timeout={1000}
        classNames={"fade"}
      >
        <div>
          {activeItem ? (
            <ScrollLocking>
              <Detail
                item={activeItem}
                paginatePrev={this.prev}
                paginateNext={this.next}
                first={this.state.index === 0}
                last={this.state.index === this.props.items.length - 1}
                closeCallback={this.removeActive}
                kioskSection={this.props.kioskSection}
              />
            </ScrollLocking>
          ) : null}
        </div>
      </CSSTransition>
    );
  }
}

export default connect(Active.mapStateToProps)(Active);
