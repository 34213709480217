import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Wrapper from "./Wrapper";

export default class PaginatedListRouter extends PureComponent {
  static displayName = "Shared.PaginatedList.Router";

  static propTypes = {
    items: PropTypes.array
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/resources/poster/:posterId?"
            render={props => <Wrapper {...props} items={this.props.items} />}
          />
          <Route
            path="/resources/posters"
            render={props => <Wrapper {...props} items={this.props.items} />}
          />
        </Switch>
      </Router>
    );
  }
}
