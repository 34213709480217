import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import dom from "../helpers/dom";

import ScrollLock from "react-scroll-lock-component";

export default class ScrollLocking extends PureComponent {
  static displayName = "HigherOrder.ScrollLocking";

  static propTypes = {
    children: PropTypes.node
  }

  componentDidMount() {
    dom.addClass(document.querySelector("body"), "hide-overflow-y");
  }

  componentWillUnmount() {
    dom.removeClass(document.querySelector("body"), "hide-overflow-y");
  }

  render() {
    return <ScrollLock>{this.props.children}</ScrollLock>;
  }
}
