import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import NoResults from "./NoResults";
import classNames from "classnames";
import get from "lodash/get";
import debounce from "lodash/debounce";

export default class ThumbnailList extends PureComponent {
  static displayName = "Shared.ThumbnailList";

  static propTypes = {
    items: PropTypes.array,
    sort: PropTypes.string,
    itemTitle: PropTypes.string,
    clearRoute: PropTypes.string,
    onLink: PropTypes.func,
    form: PropTypes.func,
    formFilters: PropTypes.object,
    filterSelections: PropTypes.object,
    formSearch: PropTypes.string,
    formFooter: PropTypes.string,
    jumboThreshold: PropTypes.array,
    noResults: PropTypes.bool,
    layoutsKey: PropTypes.string,
    beginQuery: PropTypes.string,
    pageStart: PropTypes.number
  };

  constructor() {
    super();

    // Static aspect threshold to use in
    // row calculations
    this.at = 1.2;
    // Static breakpoint used to swap layouts
    this.break70 = 760;
    // Static random seed for multi-layout options
    this.seed = Math.floor(Math.random() * (3 - 1) + 1);

    // Column size is set at constructor and on resize
    this.state = {
      rows: [],
      columns: window.innerWidth >= this.break70 ? 3 : 2
    };

    this.maybeChangeColumns = this.maybeChangeColumns.bind(this);
  }

  componentDidMount() {
    if (this.props.items && this.props.items.length > 0) {
      const rows = this.buildRows(
        this.props.items,
        this.state.columns,
        this.props.layoutsKey
      );
      this.setState({ rows });
    }

    this.debouncedColumnChange = debounce(this.maybeChangeColumns, 350);

    window.addEventListener("resize", this.debouncedColumnChange);
  }

  componentDidUpdate(prevProps, prevState) {
    // Update the rows only if the items or columns changed
    if (
      prevState.columns !== this.state.columns ||
      this.props.items !== prevProps.items
    ) {
      const rows = this.buildRows(
        this.props.items,
        this.state.columns,
        this.props.layoutsKey
      );
      this.setState({
        rows: [].concat(rows)
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedColumnChange);
  }

  getSortValue(item) {
    let val = null;

    switch (this.props.sort) {
      case "az":
      case "za":
        val = item.artwork.sort_title.substring(0, 1).toUpperCase();
        break;
      default:
        val = get(item, "artwork.creation_year_end", null);
        break;
    }

    return val;
  }

  maybeChangeColumns() {
    // Only update state if breakpoint has been crossed
    if (window.innerWidth < this.break70 && this.state.columns === 3) {
      // Size down to 2 columns (from 3)
      this.setState({
        columns: 2
      });
    } else if (window.innerWidth >= this.break70 && this.state.columns === 2) {
      // Size down to 3 columns (from 2)
      this.setState({
        columns: 3
      });
    }
  }

  maybeAddSort(prevSort, group, index) {
    const compareSort =
      index === 0 ? prevSort : this.getSortValue(group[index - 1]);
    return compareSort !== this.getSortValue(group[index])
      ? this.getSortValue(group[index])
      : null;
  }

  makeLayout(group, layout, prevSort) {
    let pick = layout;
    let nextSort = prevSort;
    let hasDouble = false;

    if (layout.length > 1 && Array.isArray(layout[0])) {
      // If there's more than one layout, pick at random
      // CAREF
      // Get a static index from the group (either ID or index)
      const index = group[0].index ? group[0].index : group[0].id;
      const selection = (index + this.seed) % layout.length;

      pick = layout[selection];
    }

    const row = pick.map((size, index) => {
      const content = [];

      // Figure out size
      if (size === "double") {
        const [a, b] = [group[index], group[index + 1]];

        // If double contents have separate index markers, just push
        // a single item
        if (this.props.sort && this.getSortValue(a) !== this.getSortValue(b)) {
          content.push(a);
        } else {
          // Otherwise push both items in the double, and let the
          // next item know
          hasDouble = true;
          content.push(a, b);
        }
      } else {
        // Just push the item (or skip one if there's been a double)
        if (hasDouble) {
          content.push(group[index + 1]);
        } else {
          content.push(group[index]);
        }
      }

      // Add a sort marker if sorting
      // Preemptively set it to null, in case it isn't used
      let sort = null;
      if (this.props.sort) {
        sort = this.maybeAddSort(prevSort, group, index);
        // Track next sort to pass it up to row builder
        if (sort) nextSort = sort;
      }

      return { size, content, sort };
    });

    return [row, nextSort];
  }

  makeDefaultLayout(columns, group) {
    const layout = [];
    for (let i = 0; i < columns; i++) {
      if (group[i] !== undefined) layout.push("single");
    }

    return layout;
  }

  makeRow(columns, group, layouts, uj, jt, currentSort) {
    const [type, sizes] = [
      `${columns}${uj <= 0 ? ":jumbo" : ""}`,
      group
        .map(item => {
          // CAREF
          const artwork = item.artwork ? item.artwork : item.hero_artwork;
          return artwork.aspect_ratio > this.at ? "wide" : "tall";
        })
        .join("/")
    ];

    // Pair layout based on image sizes
    // Or use the default
    const layout = get(
      layouts,
      `${type}.${sizes}`,
      this.makeDefaultLayout(columns, group)
    );

    const [row, newSort] = this.makeLayout(group, layout, currentSort);

    // Count all the items in the row after it is formed
    const count = row.reduce((sum, value) => {
      return sum + value.content.length;
    }, 0);

    // Update rows until jumbo
    const newUj = uj <= 0 ? jt : uj - 1;

    return [row, count, newUj, newSort];
  }

  defaultLayouts() {
    // wild and crazy grid with doubles and jumbos
    return {
      "3:jumbo": {
        "wide/tall/wide": ["jumbo", "single"],
        "wide/wide/tall": [
          ["single", "jumbo"],
          ["jumbo", "single"]
        ],
        "wide/tall/tall": ["jumbo", "single"],
        "wide/tall": ["jumbo", "single"],
        "tall/wide/tall": ["single", "jumbo"],
        "tall/wide/wide": ["single", "jumbo"],
        "wide/wide/wide": [
          ["jumbo", "double"],
          ["double", "jumbo"]
        ]
      },
      "2:jumbo": {
        "wide/wide/wide": ["jumbo"],
        "wide/wide/tall": ["jumbo"],
        "wide/tall/wide": ["jumbo"],
        "wide/tall/tall": ["jumbo"],
        "tall/wide/wide": ["single", "double"]
      },
      "2": {
        "wide/wide/tall": ["double", "single"],
        "tall/wide/wide": ["single", "double"]
      }
    };
  }

  buildRows(items, columns, layoutsKey = "default") {
    const pjt = this.props.jumboThreshold;
    // Check screen width and choose appropriate row builder
    // Initialize row variables
    const rows = [];
    // Counter until jumbo image can be used
    let uj = 0;
    // Track sorting across rows
    let currentSort = null;
    // Reset threshold until jumbo image can be used
    // This assignment logic is just for convenience
    let jt = columns === 3 ? 2 : 1;
    // Override with props if they exist (must be 2)
    if (pjt && pjt.length > 1) {
      jt = columns === 3 ? pjt[0] : pjt[1];
    }

    let i = 0;

    let layouts;
    if (layoutsKey === "basic") {
      layouts = {}; // standard grid, no special layouts
    }

    if (typeof layouts === "undefined") {
      layouts = this.defaultLayouts();
    }

    while (i < items.length) {
      const group = [items[i]];
      if (items[i + 1] !== undefined) group.push(items[i + 1]);
      if (items[i + 2] !== undefined) group.push(items[i + 2]);

      // Figure out making one column
      // Force 2 column for first row due to filter outlier
      // if filters are present
      let columnCount = columns;

      if (i === 0 && this.props.formFilters) {
        columnCount = 2;
      }

      const [row, count, newUj, newSort] = this.makeRow(
        columnCount,
        group,
        layouts,
        uj,
        jt,
        currentSort
      );

      // Update uj, sort, and i
      uj = newUj;
      // Update sort if new sort isn't null
      currentSort = newSort || currentSort;
      i = i + count;

      rows.push(row);
    }

    return rows;
  }

  handleLinkClick(event, item) {
    // Allow event to pass by default
    if (this.props.onLink) {
      event.preventDefault();
      this.props.onLink(item);
    }
  }

  renderArea(area, index) {
    const areaClass = classNames("area", {
      jumbo: area.size === "jumbo",
      double: area.size === "double"
    });

    return (
      <div className={areaClass} key={index}>
        {area.sort ? this.renderSortMarker(area.sort) : null}
        {area.content.map(item => {
          return this.renderItem(item, area.size);
        })}
      </div>
    );
  }

  renderSortMarker(sort) {
    return (
      <div className={`sort-marker ${this.props.sort}`}>
        <div className="line" />
        <span className="index">{sort}</span>
      </div>
    );
  }

  // Find the appropriate image relation for the image size we are rendering.
  // Always fall back to the original, but let's use the small (400px) or the
  // medium (750px) when better suited.
  getResizedArtwork(artwork, size) {
    let url = artwork.original_image_public_url;
    if (artwork.resized.length !== 0) {
      if (size === "jumbo") {
        if (artwork.resized.medium) {
          url = artwork.resized.medium;
        }
      } else {
        if (artwork.resized.small) {
          url = artwork.resized.small;
        } else if (artwork.resized.medium) {
          url = artwork.resized.medium;
        }
      }
    }
    return url;
  }

  renderCaption(item) {
    // Render artwork or group caption, based on Count
    let output = false;
    if (item.artwork_count) {
      output = (
        <figcaption className="caption-secondary">
          <h3 className="title">{item.name}</h3>
          {`${item.artwork_count} works`}
        </figcaption>
      );
    } else {
      output = (
        <figcaption className="caption-primary">
          {item.artwork.title_comma_year}
        </figcaption>
      );
    }

    return output;
  }

  renderItem(item, size) {
    const id = item.index ? item.index : item.id;
    const artwork = item.artwork ? item.artwork : item.hero_artwork;
    // Pick URL based on slug
    // CAREF
    const url = item.slug ? `/series/${item.slug}` : "#";

    return (
      <a
        key={id}
        href={url}
        className="item-link"
        onClick={event => {
          this.handleLinkClick(event, item);
        }}
      >
        <figure>
          <div className="image-wrapper">
            <img
              src={this.getResizedArtwork(artwork, size)}
              alt={get(artwork, "image.description", "")}
            />
          </div>
          {this.renderCaption(item)}
        </figure>
      </a>
    );
  }

  renderList() {
    return this.state.rows.map(row => {
      return row.map((area, index) => {
        return this.renderArea(area, index);
      });
    });
  }

  render() {
    const items = this.props.items;
    // Setup route links for first page
    const beginQuery = this.props.beginQuery
      ? `/?${this.props.beginQuery}`
      : "";
    const beginRoute = this.props.clearRoute + beginQuery;

    return (
      <div>
        <nav className="items">
          {this.props.form && this.props.formFilters ? (
            // Render list filters if they exist
            <div className="area filters">
              <this.props.form
                filters={this.props.formFilters}
                filterSelections={this.props.filterSelections}
                search={this.props.formSearch}
              />
              {this.props.formFooter ? (
                <div className="form-footer">
                  <div
                    className="container"
                    dangerouslySetInnerHTML={{
                      __html: this.props.formFooter
                    }}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {/* Render paginated notice if not on page 1 */}
          {/* eslint-disable indent */}
          {!this.props.noResults &&
          this.props.pageStart &&
          this.props.pageStart > 1 ? (
            <div className="flash-container">
              <div className="flash-notice">
                <header>
                  <h3>
                    {`You're starting from page ${this.props.pageStart} ` +
                      "of this list."}
                  </h3>
                </header>
                <a href={beginRoute} className="reset-link">
                  {"Click here to start from page 1"}
                </a>
              </div>
            </div>
          ) : null}
          {/* eslint-enable indent */}
          {/* Render main list */}
          {items && items.length > 0 ? this.renderList() : null}
          {this.props.noResults ? (
            // Render no results component if parent has determined no results
            <div className="flash-container border tall">
              <NoResults
                search={this.props.formSearch}
                itemTitle={this.props.itemTitle}
                clearRoute={this.props.clearRoute}
                filters={this.props.formFilters}
                filterSelections={this.props.filterSelections}
              />
            </div>
          ) : null}
        </nav>
      </div>
    );
  }
}
