import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class PosterMeta extends PureComponent {
  static displayName = "Poster.Meta";

  static propTypes = {
    item: PropTypes.object
  };

  render() {
    const item = this.props.item;

    return (
      <div className="details">
        <div className="title">
          <span
            dangerouslySetInnerHTML={{
              __html: item.title
            }}
          />
          <span className="year">,&nbsp;{item.year}</span>
        </div>
        <div className="meta">
          <div className="info">
            <ul>
              <li>
                <span className="label">{item.place ? "Place" : ""}</span>
                <p>{item.place}</p>
              </li>
            </ul>
          </div>
          <div className="info">
            <ul>
              {item.dimensions ? (
                <li>
                  <span className="label">{"Dimensions"}</span>
                  <p>{item.dimensions}</p>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
