import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class CaptionImage extends PureComponent {
  static displayName = "Content.CaptionImage";

  static propTypes = {
    image: PropTypes.string.isRequired,
    caption: PropTypes.string,
    linked: PropTypes.bool,
    onClick: PropTypes.func,
    altText: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    loading: PropTypes.oneOf(["lazy", "priority"]),
  };

  componentDidMount() {
    // Only bind if modernizr hasn't detected touchevents
    if (!window.Modernizr || window.Modernizr.touchevents === false) {
      this.image.addEventListener("mouseenter", this.showCaption);
      this.image.addEventListener("mouseleave", this.hideCaption);
    }
  }

  componentWillUnmount() {
    this.image.removeEventListener("mouseenter", this.showCaption);
    this.image.removeEventListener("mouseleave", this.hideCaption);
  }

  showCaption = () => {
    // Get caption content height everytime
    const contentHeight = this.caption.offsetHeight;
    this.wrapper.style.height = contentHeight + "px";
  };

  hideCaption = () => {
    this.wrapper.style.height = 0;
  };

  render() {
    const imageClass = this.props.linked ? "image linked" : "image";
    const clickProps = this.props.onClick
      ? {
          role: "button",
          tabIndex: 0,
          onClick: this.props.onClick,
        }
      : {};

    return (
      <figure
        className={imageClass}
        ref={(i) => {
          this.image = i;
        }}
        {...clickProps}
      >
        <img
          src={this.props.image}
          width={this.props.width}
          height={this.props.height}
          alt={this.props.altText || ""}
          loading={this.props.loading || "lazy"}
        />
        <figcaption
          className="caption-wrapper"
          ref={(w) => {
            this.wrapper = w;
          }}
        >
          <div
            className="caption"
            ref={(c) => {
              this.caption = c;
            }}
          >
            {this.props.caption}
          </div>
        </figcaption>
      </figure>
    );
  }
}
