import get from "lodash/get";

const RefException = function(message) {
  this.name = "JS Component Ref Error";
  this.message = message;
};

const refHelpers = {
  get: function(parent, name) {
    return parent.querySelector(`[data-ref=${name}]`);
  },
  all: function(parent) {
    const refEls = [...parent.querySelectorAll("[data-ref]")];
    const refs = {};

    refEls.forEach(el => {
      const refName = el.getAttribute("data-ref");
      if (refName && refName !== "") {
        if (get(refs, refName)) {
          throw new RefException(
            `Duplicate ref found: ${refName}\n` +
              "Every ref must have a unique value."
          );
        } else {
          refs[refName] = el;
        }
      }
    });

    return refs;
  }
};

export default refHelpers;
