// Plain JS class converted from react component here:
// https://github.com/orteth01/react-scroll-lock-component/
// import dom from "../helpers/dom";

const tab = {
  keyCode: 9,
  key: "Tab",
  code: "Tab"
};

const esc = {
  keyCode: 27,
  keys: ["Esc", "Escape"],
  which: 27,
  code: "Escape"
};

export default class FocusTrap {
  constructor({ onEscape }) {
    this.trapEl = null;
    this.onEscape = onEscape;
  }

  trap(trapEl, toggleEl) {
    if (!trapEl) return;

    // Set a new trap target
    this.untrap(this.trapEl);
    this.trapEl = trapEl;
    this.toggleEl = toggleEl;

    if (!this.focusable || !this.focusable.length) return;
    this.focusable[0].focus();

    window.addEventListener("keydown", this.handleKeyDown);
  }

  untrap(trapEl, toggleEl) {
    if (!trapEl) return;
    window.removeEventListener("keydown", this.handleKeyDown);
    if (trapEl === this.trapEl) {
      this.trapEl = null;
      this.toggleEl = null;
    }
  }

  get focusable() {
    const focusable = [
      "button",
      "[href]",
      "input",
      "select",
      "textarea",
      '[tabindex]:not([tabindex="-1"])'
    ];
    return Array.from(this.trapEl.querySelectorAll(focusable.join(",")));
  }

  handleKeyDown = event => {
    event.stopPropagation();

    this.handleEscape(event);
    this.handleTab(event);
  };

  handleEscape({ key, keyCode, which, code }) {
    const isEscPressed =
      esc.keys.includes(key) ||
      keyCode === esc.keyCode ||
      which === esc.which ||
      code === esc.code;
    if (!isEscPressed) return;

    if (this.onEscape) {
      this.onEscape(this.trapEl);
    }
    this.untrap(this.trapEl);
  }

  // Handle tab events
  handleTab(event) {
    const { key, keyCode, shiftKey, code } = event;
    const isTabPressed =
      key === tab.key || keyCode === tab.keyCode || code === tab.code;
    if (!isTabPressed) return;

    // const focusable = [this.toggleEl].concat(this.focusable);
    const focusable = this.focusable;

    // On keydown, this is the last focused item, not the current
    const focusedItem = document.activeElement;
    const focusableIndex = focusable.indexOf(focusedItem);

    const lastIndex = shiftKey
      ? focusableIndex === 0
      : focusableIndex === focusable.length - 1;
    if (!lastIndex) return;

    // Prevent the next tab and set focus to first/last of focusable elements
    event.preventDefault();
    const nextFocus = shiftKey ? focusable[focusable.length - 1] : focusable[0];

    nextFocus.focus();
  }
}
