import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { InputResettable } from "../shared";
import classNames from "classnames";
import queryString from "query-string";

export default class SeriesHeader extends PureComponent {
  static displayName = "Series.Header";

  static propTypes = {
    search: PropTypes.string,
    searchPrompt: PropTypes.string,
    sort: PropTypes.string
  };

  handleInputSubmit = (event, search) => {
    // Push search input directly to query string
    this.pushQuery({ search });
  };

  handleSortChange = event => {
    this.pushQuery(null, {
      sort: event.target.value
    });
  };

  pushQuery(search, sort = {}) {
    const location = window.location;
    // Get current query params
    const current = queryString.parse(location.search);

    // if there's a search param, remove sort from current
    if (search) delete current.sort;

    // Generate params and pass to URL
    const next = Object.assign(current, search, sort);
    location.href =
      location.origin + location.pathname + "?" + queryString.stringify(next);
  }

  render() {
    // Disable sort select if search is active
    const selectClass = classNames("input-select-secondary", {
      disabled: this.props.search
    });

    return (
      <header className="list-header-primary width-90 flex-60">
        <InputResettable
          onSubmit={this.handleInputSubmit}
          placeholder={this.props.searchPrompt}
          value={this.props.search}
        />

        <div className="sort">
          <div className={selectClass}>
            <select
              disabled={this.props.search}
              value={this.props.search ? "rel" : this.props.sort}
              onChange={this.handleSortChange}
              aria-label="Sort by"
            >
              {this.props.search ? (
                <option value="rel" disabled>
                  Relevance
                </option>
              ) : null}
              <option value="">Newest</option>
              <option value="oldest">Oldest</option>
              <option value="az">A-Z</option>
              <option value="za">Z-A</option>
            </select>
          </div>
        </div>
      </header>
    );
  }
}
