import AnchorNavFixed from "./AnchorNavFixed";
import Callout from "./Callout";
import DropdownClick from "./DropdownClick";
import KioskAware from "./KioskAware";
import KioskCookie from "./KioskCookie";
import KioskLinks from "./KioskLinks";
import KioskRedirect from "./KioskRedirect";
import ListPeek from "./ListPeek";
import LogoAnimated from "./LogoAnimated";
import PaginationInput from "./PaginationInput";
import SearchOpen from "./SearchOpen";
import SmoothAnchor from "./SmoothAnchor";
import SmoothAnchorTracker from "./SmoothAnchorTracker";
import Switch from "./Switch";

export default {
  AnchorNavFixed,
  Callout,
  DropdownClick,
  KioskAware,
  KioskCookie,
  KioskLinks,
  KioskRedirect,
  ListPeek,
  LogoAnimated,
  PaginationInput,
  SearchOpen,
  SmoothAnchor,
  SmoothAnchorTracker,
  Switch
};
