import Cookies from "js-cookie";
import dom from "../helpers/dom";
import refs from "../helpers/refs";

import get from "lodash/get";

export default class KioskCookie {
  constructor(el, props) {
    // Only enable button if the section is set
    if (get(props, "section")) {
      this.cooKey = `kiosk_${props.section}`;

      // Get button from refs
      const button = refs.get(el, "button");
      const next = refs.get(el, "continue");

      // Set button text based on current cookie state
      this.toggleCssState(el);

      // Bind click to turn cookie on and off
      button.addEventListener("click", () => {
        this.toggleKiosk(el);
      });

      next.addEventListener("click", () => {
        if (Cookies.get(this.cooKey)) {
          window.location.href = `/${props.section}`;
        }
      });
    }
  }

  toggleCssState(el) {
    const enabled = Cookies.get(this.cooKey);
    if (enabled) {
      dom.removeClass(el, "disabled");
      dom.addClass(el, "enabled");
    } else {
      dom.removeClass(el, "enabled");
      dom.addClass(el, "disabled");
    }
  }

  toggleKiosk(el) {
    const enabled = Cookies.get(this.cooKey);
    if (enabled) {
      Cookies.remove(this.cooKey);
    } else {
      Cookies.set(this.cooKey, 1, { expires: 365 });
    }

    this.toggleCssState(el);
  }
}
