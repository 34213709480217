import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import classNames from "classnames";
import queryString from "query-string";

import Filters from "./filters";

class ArtworkFiltersForm extends PureComponent {
  static displayName = "Artwork.Form";

  static propTypes = {
    filters: PropTypes.object,
    filterSelections: PropTypes.object,
    search: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      // Track filter state before going into route
      // Note that these can be reset by the route at any time
      filterSelections: {},
      filteredYears: "",
      dirty: false,
      reset: false,
      search: ""
    };

    // Start a blank object to keep track of filters
    this.filterRefs = {};
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (!state.dirty && !state.reset) {
      const { filterSelections, search } = nextProps;

      return {
        filterSelections: Object.assign({}, filterSelections),
        filteredYears: filterSelections.years,
        search,
        reset: false
      };
    }

    return null;
  }

  handleYearChange = years => {
    this.setState({
      dirty: true,
      filterSelections: Object.assign({}, this.state.filterSelections, {
        years
      }),
      filteredYears: null
    });
  };

  handleMediaReset = (push = false) => {
    // Set a dirty state unless media reset is pushed
    const dirty = !push;
    this.setState(
      {
        dirty,
        reset: push,
        filterSelections: Object.assign({}, this.state.filterSelections, {
          media: []
        })
      },
      () => {
        // Only push filters if pushed
        if (push) {
          this.pushFilters();
        }
      }
    );
  };

  // Toggles a single id in filter selected
  handleMediumChange = id => {
    const current = this.state.filterSelections.media
      ? this.state.filterSelections.media.slice()
      : [];
    const index = current.indexOf(id);

    let next = {};

    if (index > -1) {
      // Media is selected, so remove it
      current.splice(index, 1);
      next = { media: current };
    } else {
      // Media isn't currently selected, so add it
      next = { media: current.concat([id]) };
    }

    this.setState({
      dirty: true,
      filterSelections: Object.assign({}, this.state.filterSelections, next)
    });
  };

  handleMediaChange = (ids, action) => {
    // Check if only one ID, or array of ids and use appropriate function.
    if (typeof ids === "number") {
      // If so, run without action
      this.handleMediumChange(ids);
    } else {
      // Add or remove multiple filters
      const current = this.state.filterSelections.media
        ? this.state.filterSelections.media.slice()
        : [];

      let next = [];

      if (action === "add") {
        // Only use mediums that aren't currently added
        const addable = [];
        ids.forEach(id => {
          if (current.indexOf(id) <= -1) addable.push(id);
        });
        // Only add ids that aren't already there
        next = { media: current.concat(addable) };
      } else {
        // Remove all of the ids from current selection
        ids.forEach(id => {
          const index = current.indexOf(id);
          if (index > -1) current.splice(index, 1);
        });
        next = { media: current };
      }

      this.setState({
        dirty: true,
        filterSelections: Object.assign({}, this.state.filterSelections, next)
      });
    }
  };

  handleSearchChange = event => {
    this.setState({
      dirty: true,
      search: event.target.value
    });
  };

  handleEnterSubmit = event => {
    if (event.keyCode === 13) {
      this.pushFilters();
    }
  };

  buildQueryString(location) {
    // This will be an actual query object or an empty one if
    // search is null
    let filterQuery = {};

    const selections = this.state.filterSelections;

    // Pass year query as is
    if (selections.years) {
      filterQuery.years = selections.years;
    }

    // Rejoin media filters and pass to query
    if (selections.media && selections.media.length > 0) {
      filterQuery.media = selections.media.join(",");
    }

    const search = this.state.search;
    if (search.length > 0) {
      filterQuery = Object.assign(filterQuery, { search });
    } else {
      // Otherwise, remove the 'search' property
      delete filterQuery.search;
    }

    return queryString.stringify(filterQuery);
  }

  pushFilters = event => {
    // Cancel event if called directly
    if (event) event.preventDefault();
    const { match, history, location } = this.props;

    history.push(`${match.path}/?${this.buildQueryString(location)}`);

    // Reset form state
    this.setState({
      dirty: false
    });
  };

  buildFilters() {
    const filters = this.props.filters;

    return [this.buildYearFilter(filters.years)];
  }

  render() {
    const filterData = this.props.filters;
    const selections = this.state.filterSelections;
    const searchStyle = {
      fontSize: this.state.search.length > 0 ? "18px" : "13px"
    };

    const buttonClass = classNames("submit", {
      disabled: !this.state.dirty
    });

    return (
      <div className="artwork-filters-form">
        <div className="form-primary">
          <div className="text-search">
            <button type="submit" onClick={this.pushFilters}>
              <span className="screen-reader-text">
                Click to submit search/filters
              </span>
            </button>
            <input
              type="text"
              style={searchStyle}
              value={this.state.search}
              aria-label="Search"
              placeholder="Search anything..."
              onChange={this.handleSearchChange}
              onKeyUp={this.handleEnterSubmit}
            />
          </div>
          {filterData.years ? (
            <Filters.Years
              years={filterData.years}
              selected={selections.years}
              filtered={this.state.filteredYears}
              onChange={this.handleYearChange}
              onEnter={this.pushFilters}
            />
          ) : null}
          {filterData.media ? (
            <Filters.Media
              media={filterData.media}
              selections={selections.media}
              handleMediaChange={this.handleMediaChange}
              onSubmit={this.pushFilters}
              onReset={this.handleMediaReset}
            />
          ) : null}
          <button
            className={buttonClass}
            onClick={this.pushFilters}
            disabled={!this.state.dirty}
          >
            Done
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(ArtworkFiltersForm);
