import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import KioskAware from "../higherOrder/KioskAware";
import Overview from "./Overview";

export default class ChronologyWrapper extends PureComponent {
  static displayName = "Chronology.OverviewWrapper";

  static propTypes = {
    years: PropTypes.array,
    default: PropTypes.object,
    kioskSection: PropTypes.string
  };

  render() {
    return (
      <KioskAware section={this.props.kioskSection}>
        <Overview years={this.props.years} default={this.props.default} />
      </KioskAware>
    );
  }
}
