import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export default class ArtworkFiltersMediaGroup extends PureComponent {
  static displayName = "Artwork.Filters.MediaGroup";

  static propTypes = {
    group: PropTypes.object,
    selected: PropTypes.array,
    onMediaChange: PropTypes.func
  };

  constructor() {
    super();
    this.state = {
      allSelected: false,
      open: false
    };
  }

  componentDidMount() {
    this.setStateFromProps(this.props);
  }

  componentDidUpdate(props) {
    if (props.selected !== this.props.selected) {
      this.setStateFromProps(props);
    }
  }

  setStateFromProps(props) {
    let allSelected = true;
    props.group.media.forEach(medium => {
      // If any of the media can't be found in the selection
      if (props.selected.indexOf(medium.id) <= -1) {
        // All selected stays false
        allSelected = false;
      }
    });

    this.setState({ allSelected });
  }

  buildMediaList(media) {
    const selected = this.props.selected;

    return media.map(medium => {
      const active = selected.indexOf(medium.id) > -1;
      const listingClass = active ? "active" : null;
      return (
        <li className={listingClass} key={medium.id}>
          <button
            onClick={() => {
              this.props.onMediaChange(medium.id);
            }}
          >
            {medium.title}
          </button>
        </li>
      );
    });
  }

  handleChangeAll = () => {
    // Grab all the ids
    const ids = this.props.group.media.map(m => {
      return m.id;
    });
    const action = this.state.allSelected ? "remove" : "add";
    this.props.onMediaChange(ids, action);
  };

  handleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const group = this.props.group;
    const mediaClass = (group.slug ? group.slug : group.title)
      .replace(/\s+/g, "-")
      .toLowerCase();
    const groupClass = classNames("group " + mediaClass, {
      open: this.state.open
    });

    return (
      <div key={group.id} className={groupClass}>
        <header>
          <h5
            className="title"
            onClick={this.handleOpen}
            role="button"
            tabIndex="0"
          >
            {group.title}
          </h5>
          <div className="utility">
            <button className="all" onClick={this.handleChangeAll}>
              {this.state.allSelected ? "Clear" : "All"}
            </button>
            <i
              className="caret"
              onClick={this.handleOpen}
              role="button"
              tabIndex="0"
            />
          </div>
        </header>
        {group.media.length > 0 ? (
          <ul className="filters">{this.buildMediaList(group.media)}</ul>
        ) : null}
      </div>
    );
  }
}
