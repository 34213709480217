import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import MatchListing from "./MatchListing";

export default class ThumbnailListMatch extends PureComponent {
  static displayName = "Shared.ThumbnailListMatch";

  static propTypes = {
    items: PropTypes.array,
    onClickItem: PropTypes.func
  };

  chunkArray(original, size) {
    const copy = original.slice();
    const chunked = [];
    while (copy.length) {
      chunked.push(copy.splice(0, size));
    }

    return chunked;
  }

  renderColumns(chunkSize) {
    // Chunk items by size
    const chunkedItems = this.chunkArray(this.props.items, chunkSize);

    // Iterate with special column class
    return chunkedItems.map((set, setIndex) => {
      return (
        <div key={setIndex} className={`column-${chunkSize}`}>
          {[...Array(chunkSize).keys()].map(index => {
            // Iterate over each column (instead of each item)
            // so that placeholders can be generated if items
            // don't exist
            return set[index] ? (
              <MatchListing
                key={index}
                item={set[index]}
                click={this.props.onClickItem}
                column={index + 1}
              />
            ) : (
              <figure className={`image link image-${index + 1}`} />
            );
          })}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="thumbnail-list-match">
        {this.renderColumns(2)}
        {this.renderColumns(3)}
      </div>
    );
  }
}
