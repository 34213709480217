import dom from "./dom";

export default class Disabler {
  constructor() {
    dom.ready(this.init);
  }

  init = () => {
    document.addEventListener("keydown", this.preventShortcuts, false);
    document.addEventListener("contextmenu", this.preventContextMenu, false);
  };

  disableEvent = e => {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (window.event) {
      window.event.cancelBubble = true;
    }
    e.preventDefault();
    return false;
  };

  preventContextMenu = e => {
    this.disableEvent(e);
  };

  preventShortcuts = e => {
    // "Ctrl + Shift + I" key
    if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
      this.disableEvent(e);
    }
    // "Ctrl + Shift + J" key
    if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
      this.disableEvent(e);
    }
    // "Ctrl + S" key
    if (
      e.keyCode === 83 &&
      (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
    ) {
      this.disableEvent(e);
    }
    // "Ctrl + U" key
    if (e.ctrlKey && e.keyCode === 85) {
      this.disableEvent(e);
    }
    // "F12" key
    if (e.keyCode === 123) {
      this.disableEvent(e);
    }
  };
}
