const domHelpers = {
  hasClass: function(el, className) {
    if (el.classList) {
      return el.classList.contains(className);
    } else {
      return !!el.className.match(
        new RegExp("(\\s|^)" + className + "(\\s|$)")
      );
    }
  },

  hasAllClasses: function(el, classNames) {
    // Returns false if any classes are missing
    let result = true;
    classNames.forEach(className => {
      if (!this.hasClass(el, className)) {
        result = false;
      }
    });

    return result;
  },

  addClass: function(el, classNames) {
    const classes =
      typeof classNames === "string" ? classNames.split(" ") : classNames;

    if (el.classList) {
      classes.forEach(className => {
        el.classList.add(className);
      });
    } else {
      classes.forEach(className => {
        if (!this.hasClass(el, className)) {
          el.className += " " + className;
        }
      });
    }
  },

  // Remove one or more classes from an element
  removeClass: function(el, classNames) {
    // Make an array of classes, even if only one was passed
    const classes =
      typeof classNames === "string" ? classNames.split(" ") : classNames;

    if (el.classList) {
      classes.forEach(className => {
        el.classList.remove(className);
      });
    } else {
      classes.forEach(className => {
        if (this.hasClass(el, className)) {
          const reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
          el.className = el.className.replace(reg, " ");
        }
      });
    }
  },

  ready: function(f) {
    if (document.readyState !== "loading") {
      f();
    } else {
      document.addEventListener("DOMContentLoaded", f);
    }
  }
};

export default domHelpers;
