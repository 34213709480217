import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import get from "lodash/get";

export default class MatchListing extends PureComponent {
  static displayName = "Shared.MatchListing";

  static propTypes = {
    item: PropTypes.object,
    column: PropTypes.number,
    click: PropTypes.func
  };

  constructor() {
    super();

    this.state = {
      hover: false
    };
  }

  hover = () => {
    this.setState({
      hover: true
    });
  };

  hoverOff = () => {
    this.setState({
      hover: false
    });
  };

  handleClick = event => {
    this.props.click(this.props.item.id);
  };

  render() {
    const { item, column } = this.props;
    const hover = this.state.hover;

    return [
      <figure
        key={`f-${item.id}`}
        onClick={this.handleClick}
        onMouseEnter={this.hover}
        onMouseLeave={this.hoverOff}
        className={classNames(`image link image-${column}`, { hover })}
        role="button"
        tabIndex="0"
      >
        {item.resized.small ? (
          <img src={item.resized.small} alt={get(item, "image_alt", "")} />
        ) : (
          <div className="no-image">
            <span>{"Image not available"}</span>
          </div>
        )}
      </figure>,
      <div
        key={`c-${item.id}`}
        onClick={this.handleClick}
        onMouseEnter={this.hover}
        onMouseLeave={this.hoverOff}
        className={classNames(`caption link caption-${column}`, { hover })}
      >
        <h3
          className="title"
          dangerouslySetInnerHTML={{
            __html: item.title
          }}
        />
        <div className="meta">
          <p className="primary">{item.year}</p>
        </div>
      </div>
    ];
  }
}
