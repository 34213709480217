import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import throttle from "lodash/throttle";

import KioskAware from "../higherOrder/KioskAware";
import YearInput from "./YearInput";

export default class ChronologyYearInputFloating extends PureComponent {
  static displayName = "Chronology.YearInputFloating";

  static propTypes = {
    years: PropTypes.array,
    focusOnLoad: PropTypes.bool,
    placeholder: PropTypes.string,
    previous: PropTypes.string,
    next: PropTypes.string,
    onUpdate: PropTypes.func,
    kioskSection: PropTypes.string
  };

  constructor() {
    super();
    this.threshold = 20;

    this.state = {
      fixed: false
    };
  }

  componentDidMount() {
    // Check scroll position
    this.setStateFromScroll();

    this.throttledScroll = throttle(this.setStateFromScroll, 50);

    window.addEventListener("scroll", this.throttledScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledScroll);
  }

  setStateFromScroll = () => {
    if (window.scrollY >= this.threshold && !this.state.fixed) {
      // Only set state if it needs to change
      this.setState({
        fixed: true
      });
    } else if (window.scrollY < this.threshold && this.state.fixed) {
      this.setState({
        fixed: false
      });
    }
  };

  render() {
    return (
      <KioskAware section={this.props.kioskSection}>
        <YearInput
          years={this.props.years}
          focusOnLoad={this.props.focusOnLoad}
          placeholder={this.props.placeholder}
          previous={this.props.previous}
          next={this.props.next}
          onUpdate={this.props.onUpdate}
          fixed={this.state.fixed}
          arrowConfirm={false}
        />
      </KioskAware>
    );
  }
}
