// Node modules
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import fluidvids from "fluidvids.js";

// Utility modules
import get from "lodash/get";
import unescape from "lodash/unescape";
import { series } from "async";
import dom from "./helpers/dom";
import Disabler from "./helpers/Disabler";

// Local components
import Artwork from "./artwork";
import Chronology from "./chronology";
import Content from "./content";
import Form from "./form";
import Hamburger from "./template/Hamburger";
import InjectNested from "./template/InjectNested";
import Series from "./series";
import PaginatedRouter from "./shared/paginated/Router";
import SharedHover from "./shared/SharedHover";
import Template from "./template";

export default class Loader {
  constructor(store) {
    // Setup redux store
    this.store = store;

    // Track JS components that need
    // a refresh on back/forwards
    this.refreshJs = false;

    // Initialize video library
    fluidvids.init({
      selector: ["iframe"],
      players: ["www.youtube.com"]
    });

    // Initialize loader on ready
    dom.ready(this.queueComponents);
    const disabler = new Disabler();
  }

  queueComponents = () => {
    series(
      [
        cb => {
          this.load({
            Template: {
              Hamburger,
              InjectNested
            }
          });
          cb(null, null);
        },
        cb => {
          this.load({
            Artwork,
            Chronology,
            Content,
            Form,
            Series,
            PaginatedRouter,
            SharedHover,
            Template
          });
          cb(null, null);
        },
        cb => {
          this.bindRefresh();
        }
      ],
      err => {
        // eslint-disable-next-line
        if (err) console.log(err);
      }
    );
  };

  load(components) {
    const componentRefs = [...document.querySelectorAll("[data-component]")];
    const store = this.store;

    componentRefs.forEach(el => {
      const componentName = el.dataset.component;

      if (get(components, componentName)) {
        // If component exists
        const Component = get(components, componentName);

        let props = {};

        // Bootstrap data via a data-prop attribute.
        if (get(el.dataset, "props")) {
          props = get(el.dataset, "props")
            ? Object.assign(JSON.parse(el.dataset.props))
            : null;
        }

        // Refresh JS on back/forwards if a component with
        // [data-refresh-js="something"] is encountered
        if (get(el.dataset, "refreshJs")) {
          this.refreshJs = true;
        }

        // Bootstrap data via a child script element
        // with a data-bootstrap attribute
        const bootstrapEls = el.querySelectorAll("[data-bootstrap]");
        [...bootstrapEls].forEach(bootstrapEl => {
          const raw = bootstrapEl.innerText;
          const unescaped = unescape(raw);
          props = Object.assign(props, JSON.parse(unescaped));
        });

        // Check if component is a react component
        if (
          Object.prototype.isPrototypeOf.call(React.PureComponent, Component) ||
          Object.prototype.isPrototypeOf.call(React.Component, Component)
        ) {
          this.renderReactComponent(Component, store, props, el);
        } else if (
          Object.prototype.isPrototypeOf.call(
            React.PureComponent,
            Component.WrappedComponent
          ) ||
          Object.prototype.isPrototypeOf.call(
            React.Component,
            Component.WrappedComponent
          )
        ) {
          this.renderReactComponent(Component.type, store, props, el);
        } else {
          // Instantiate a plain JS class
          const componentInstance = new Component(el, props);

          // Make component publicly accessible (on the window object)
          // If it has the dataset [data-public="something"]
          // and [data-id="something"]
          // This allows only public access to specific *instances*
          // of a class not every instance
          if (get(el.dataset, "public") && get(el.dataset, "id")) {
            // Save the instance on the window using the data-id
            window[el.dataset.id] = componentInstance;
          }
        }
      }
    });
  }

  renderReactComponent(Component, store, props, el) {
    const toRender = (
      <Provider store={store}>{React.createElement(Component, props)}</Provider>
    );

    // Instantiate a react component
    ReactDOM.render(toRender, el);
  }

  bindRefresh() {
    // If the loaded page is cached, make sure to kick off the ready event
    window.addEventListener("pageshow", event => {
      if (event.persisted) {
        window.location.reload();
      }
    });
  }
}
