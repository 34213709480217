import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import debounce from "lodash/debounce";
import sortBy from "lodash/sortBy";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import KioskAware from "../higherOrder/KioskAware";
import YearInput from "./YearInput";
import YearListing from "./YearListing";

export default class ChronologyOverview extends PureComponent {
  static displayName = "Chronology.Overview";

  static propTypes = {
    years: PropTypes.array,
    default: PropTypes.object,
    kioskMode: PropTypes.object,
    kioskSection: PropTypes.string
  };

  constructor() {
    super();

    const scrollY =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : document.body.scrollTop;

    // Prevent autofocus on touch devices and IE11
    this.autofocus = !(
      window.Modernizr.touchevents ||
      (!!window.MSInputMethodContext && !!document.documentMode)
    );

    this.state = {
      activeYear: null,
      filteredList: [],
      scrollY
    };

    this.hoverOutTime = 5000;
    this.activeTimeout = null;

    this.debouncedActive = debounce(id => {
      this.setState({
        activeYear: id
      });
    }, 500);
  }

  componentDidMount() {
    // Bind hover out for list
    this.list.addEventListener("mouseleave", this.handleHoverOut);
    this.list.addEventListener("mouseleave", this.handleHoverOut);

    window.setTimeout(() => {
      window.scroll(0, this.state.scrollY);
    });

    this.setLinksFromProps(this.props);

    if (this.props.years)
      this.setState({
        filteredList: [].concat(this.sortYearsAsc(this.props.years))
      });
  }

  componentDidUpdate(props) {
    if (this.props.years !== props.years) {
      this.setState({
        filteredList: [].concat(this.sortYearsAsc(props.years))
      });
    }

    if (this.props.kioskMode !== props.kioskMode) {
      this.setLinksFromProps(props);
    }
  }

  componentWillUnmount() {
    this.list.removeEventListener("mouseleave", this.handleHoverOut);
  }

  setLinksFromProps(props) {
    if (get(props, "kioskMode.state") && props.kioskMode.state) {
      this.setState({
        jsLinks: true
      });
    } else {
      this.setState({
        jsLinks: false
      });
    }
  }

  handleInput = (input, matches) => {
    // Clear the activeYear and timeout
    clearTimeout(this.activeTimeout);
    this.setState({ activeYear: null });

    // Filter list using a set of ids only
    if (input.length > 0 && matches) {
      this.filterListFromInput(
        matches.map(c => {
          return c.id;
        })
      );
    }

    if (input.length === 0) {
      // Reset filters if there's no input
      this.setState({
        filteredList: [].concat(this.sortYearsAsc(this.props.years))
      });
    }
  };

  filterListFromInput(matchedIds) {
    const filtered = this.props.years.filter(c => {
      return matchedIds.indexOf(c.id) > -1;
    });

    const active = filtered.length > 0 ? filtered[0].id : null;

    this.setState({
      filteredList: [].concat(filtered)
    });

    this.debouncedActive(active);
  }

  sortYearsAsc(years) {
    return sortBy(years, [
      y => {
        return parseInt(y.name);
      }
    ]);
  }

  handleHoverOut = () => {
    this.activeTimeout = setTimeout(() => {
      this.setState({ activeYear: null });
    }, this.hoverOutTime);
  };

  setActive = activeYear => {
    clearTimeout(this.activeTimeout);

    this.setState({ activeYear });
  };

  maybeLinkWithJs = (event, url) => {
    if (get(this.props, "kioskMode.state") && this.props.kioskMode.state) {
      event.preventDefault();
      window.location.href = url;
    }
  };

  yearDisplayName(year) {
    return year.override ? year.override : year.name;
  }

  renderDefault() {
    const d = this.props.default;

    return (
      <CSSTransition key={"default"} timeout={500} classNames="preview">
        <figure className="figure">
          {d.image ? <img src={d.image} alt="" /> : null}
          <figcaption>
            {d.label ? <div className="label">{d.label}</div> : null}
          </figcaption>
        </figure>
      </CSSTransition>
    );
  }

  renderActive() {
    const active = this.props.years.find(year => {
      return year.id === parseInt(this.state.activeYear);
    });

    let output = this.renderDefault();

    if (active) {
      const activeYear = active;
      const yearName = activeYear.override
        ? activeYear.override
        : activeYear.name;

      output = (
        <CSSTransition key={activeYear.id} timeout={500} classNames="preview">
          <a
            onClick={e => {
              this.maybeLinkWithJs(e, activeYear.url);
            }}
            href={activeYear.url}
            className="figure"
          >
            <figure>
              {activeYear.thumbnail ? (
                <img src={activeYear.thumbnail} alt="" />
              ) : null}

              <figcaption className="arrow">{`See ${yearName}`}</figcaption>
            </figure>
          </a>
        </CSSTransition>
      );
    }

    return output;
  }

  render() {
    const d = this.props.default;

    return (
      <div className="year-nav-primary">
        <div className="two-column-flush fill-height">
          <div className="container">
            <div className="hero-narrow">
              <img src={d.image} alt=""/>
            </div>
          </div>
          <div className="container grid">
            <div className="col left">
              <div className="fixed">
                <YearInput
                  years={this.props.years}
                  focusOnLoad={this.autofocus}
                  onUpdate={this.handleInput}
                />
                <div className="year-preview">
                  <TransitionGroup>
                    {this.state.activeYear
                      ? this.renderActive()
                      : this.renderDefault()}
                  </TransitionGroup>
                </div>
              </div>
            </div>
            <div className="col right">
              <ul
                className="year-list"
                ref={l => {
                  this.list = l;
                }}
              >
                {this.state.filteredList.map(year => {
                  return (
                    <KioskAware
                      key={year.name}
                      section={this.props.kioskSection}
                    >
                      <YearListing
                        key={year.name}
                        year={year}
                        onHover={this.setActive}
                        jsLink={this.state.jsLinks}
                      />
                    </KioskAware>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
