export default class SearchOpen {
  constructor(el, props) {
    // Secondary class that allows user to open search is instantiated
    // Dependent on Hamburger class being instantiated before this
    // with the same id passed from props
    const hamburger = props.hamburger;
    if (window[hamburger]) {
      el.addEventListener("click", event => {
        event.preventDefault();
        window[hamburger].open("search");
      });
    }
  }
}
