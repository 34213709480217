import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ScrollLocking from "../../higherOrder/ScrollLocking";
import get from "lodash/get";
import queryString from "query-string";

import ThumbnailListMatch from "../ThumbnailListMatch";
import ItemDetail from "../ItemDetail";

const landing = "/resources/posters";

export default class PaginatedListWrapper extends PureComponent {
  static displayName = "Shared.PaginatedListWrapper";

  static propTypes = {
    items: PropTypes.array,
    match: PropTypes.bool,
    history: PropTypes.object
  };

  constructor() {
    super();
    this.state = {
      mappedItems: {},
      activeItem: null,
      activeIndex: null
    };
  }

  componentDidMount() {
    this.setStateFromProps(this.props, true);
    // Set page number on init only
    this.setSearchFromProps(this.props);
    this.mapItems();
  }

  componentWillReceiveProps(props) {
    if (this.props.match !== props.match) {
      this.setStateFromProps(props);
    }
  }

  // Map items by id for faster lookup later
  mapItems() {
    const mappedItems = {};
    this.props.items.forEach((item, index) => {
      mappedItems[item.id] = index;
    });

    this.setState({
      mappedItems: Object.assign({}, mappedItems)
    });
  }

  setStateFromProps(props, page) {
    const activeId = get(props.match, "params.posterId");

    // Get active item
    // Active item will be null if it's not found
    // (from erroneous id or something)
    const activeIndex = activeId ? this.state.mappedItems[activeId] : null;

    // NB: activeIndex can be 0
    if (activeIndex !== null) {
      this.setState({
        activeIndex,
        activeItem: this.props.items[activeIndex]
      });
    } else {
      this.setState({
        activeItem: null,
        activeIndex: null
      });
    }
  }

  setSearchFromProps(props) {
    // Get search from url
    const query = queryString.parse(props.location.search);
    // Separate page value and then remove it
    const page = get(query, "page") ? parseInt(query.page) : 1;
    delete query.page;

    // Turn the query back into a string (Without page)
    const search = queryString.stringify(query);

    // TODO: Either remove page from search here or in the pagination function.
    this.setState({
      search,
      page
    });
  }

  setActive = id => {
    const history = this.props.history;
    history.push(`/resources/poster/${id}`);
  };

  clearActive = () => {
    const history = this.props.history;
    history.push(landing);
  };

  paginateItemPrev = () => {
    // Get new active index from current
    const items = this.props.items;
    const currentIndex = this.state.activeIndex;
    if (currentIndex > 0) {
      const newItem = items[currentIndex - 1];
      this.setActive(newItem.id);
    } else if (this.state.page > 1) {
      const prevPage = this.state.page - 1;
      // Get search string if it exists
      const search = this.state.search ? this.state.search + "&" : "";
      window.location.href = `${landing}?${search}page=${prevPage}`;
    }
  };

  paginateItemNext = () => {
    // Get new active index from current
    const items = this.props.items;
    const currentIndex = this.state.activeIndex;
    if (currentIndex < items.length - 1) {
      const newItem = items[currentIndex + 1];
      this.setActive(newItem.id);
    } else {
      const nextPage = this.state.page + 1;
      // Get search string if it exists
      const search = this.state.search ? this.state.search + "&" : "";
      window.location.href = `${landing}?${search}page=${nextPage}`;
    }
  };

  render() {
    return (
      <div>
        <ThumbnailListMatch
          items={this.props.items}
          onClickItem={this.setActive}
        />
        {this.state.activeItem ? (
          <ScrollLocking>
            <ItemDetail
              item={this.state.activeItem}
              meta="poster"
              paginatePrev={this.paginateItemPrev}
              paginateNext={this.paginateItemNext}
              closeCallback={this.clearActive}
            />
          </ScrollLocking>
        ) : null}
      </div>
    );
  }
}
