import Cookies from "js-cookie";

import get from "lodash/get";

export default class KioskLinks {
  constructor(el, props) {
    // Only check for kiosk if section is set
    if (get(props, "section")) {
      this.domainRegex = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;
      const cooKey = `kiosk_${props.section}`;

      // Only adjust links if the section cookie is present
      if (Cookies.get(cooKey)) {
        const links = [...document.getElementsByTagName("a")];

        links.forEach(link => {
          link.addEventListener("click", event => {
            event.preventDefault();
            this.bindLinkBehavior(link, props.section);
          });
        });
      }
    }
  }

  isDomain(href) {
    const current = window.location.href;
    return this.domainRegex.exec(current)[1] === this.domainRegex.exec(href)[1];
  }

  bindLinkBehavior(link, section) {
    // Disable any external links
    if (this.isDomain(link)) {
      const path = link.pathname;
      // Travel to links inside section with JS
      if (path.lastIndexOf(`/${section}`, 0) === 0) {
        window.location.href = link.href;
      }
    }
  }
}
