import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Swipeable } from "react-swipeable";
import classNames from "classnames";
import get from "lodash/get";

import KioskAware from "../higherOrder/KioskAware";
import ArtworkMeta from "../artwork/Meta";
import PosterMeta from "../resources/poster/Meta";

const meta = {
  artwork: ArtworkMeta,
  poster: PosterMeta
};

export default class ItemDetail extends PureComponent {
  static displayName = "Shared.Item.Detail";

  static propTypes = {
    item: PropTypes.object,
    meta: PropTypes.string,
    first: PropTypes.bool,
    last: PropTypes.bool,
    loading: PropTypes.bool,
    paginatePrev: PropTypes.func,
    paginateNext: PropTypes.func,
    closeCallback: PropTypes.func,
    kioskSection: PropTypes.string,
    disableLinks: PropTypes.bool
  };

  static defaultProps = {
    meta: "artwork"
  };

  constructor() {
    super();

    this.state = {
      overlayPrimaryFocus: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleMouseIn = this.handleMouseIn.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleKeyControl = this.handleKeyControl.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyControl);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyControl);
  }

  handleKeyControl(event) {
    switch (event.keyCode) {
      case 27:
        this.handleClose();
        break;
      case 37:
        this.props.paginatePrev();
        break;
      case 39:
        this.props.paginateNext();
        break;
    }
  }

  handleClose() {
    this.props.closeCallback();
  }

  handleMouseIn() {
    if (!this.state.loading) {
      this.setState({ overlayPrimaryFocus: true });
    }
  }

  handleMouseOut() {
    if (!this.state.loading) {
      this.setState({ overlayPrimaryFocus: false });
    }
  }

  renderMeta() {
    const Component = meta[this.props.meta];
    return Component ? (
      <KioskAware section={this.props.kioskSection}>
        <Component
          item={this.props.item}
          disableLinks={this.props.disableLinks}
        />
      </KioskAware>
    ) : null;
  }

  render() {
    const { item } = this.props;
    const overlayClasses = classNames("overlay-primary", {
      focused: this.state.overlayPrimaryFocus
    });

    const prevPagerClass = classNames("prev", {
      disabled: this.props.first
    });

    const nextPagerClass = classNames("next", {
      disabled: this.props.last
    });

    return (
      <Swipeable
        className={overlayClasses}
        trackMouse={true}
        onSwipedLeft={this.props.paginateNext}
        onSwipedRight={this.props.paginatePrev}
      >
        <div className="artwork-detail">
          <div className="container">
            <header>
              <div className="pager">
                <button
                  className={prevPagerClass}
                  onClick={this.props.paginatePrev}
                >
                  <span className="screen-reader-text">
                    {"Image can be swiped right. " +
                      "Click to go to previous artwork"}
                  </span>
                </button>
                <span className="label">Swipe</span>
                <button
                  className={nextPagerClass}
                  onClick={this.props.paginateNext}
                >
                  <span className="screen-reader-text">
                    {"Image can be swiped left. " +
                      "Click to go to next artwork"}
                  </span>
                </button>
              </div>
              <button
                aria-label="Close"
                className="button-close"
                onClick={this.handleClose}
                onMouseEnter={this.handleMouseIn}
                onMouseLeave={this.handleMouseOut}
              />
            </header>

            <figure className="image">
              <img
                src={item.original_image_public_url}
                alt={get(item, "image.description", "")}
              />
              {!this.props.first ? (
                <button
                  className="item-page-left"
                  onClick={this.props.paginatePrev}
                />
              ) : null}
              {!this.props.last ? (
                <button
                  className="item-page-right"
                  onClick={this.props.paginateNext}
                />
              ) : null}
            </figure>
            <div className="details-wrapper">{this.renderMeta()}</div>
          </div>
          {this.props.loading ? (
            <div className="loader-bar">
              <div className="progress" />
            </div>
          ) : null}
        </div>
      </Swipeable>
    );
  }
}
