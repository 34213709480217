import Active from "./Active";
import Artwork from "./Artwork";
import CaptionImage from "./CaptionImage";
import Slide from "./Slide";
import Slider from "./Slider";
import TabList from "./TabList/Wrapper";

export default {
  Active,
  Artwork,
  CaptionImage,
  Slider,
  Slide,
  TabList
};
