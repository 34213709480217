import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ArtworkHeader extends PureComponent {
  static displayName = "Artwork.Header";

  static propTypes = {
    description: PropTypes.string,
    subtitle: PropTypes.string,
    count: PropTypes.number,
    sort: PropTypes.string,
    disabled: PropTypes.bool,
    onSort: PropTypes.func,
    total: PropTypes.number
  };

  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    const total = this.props.total;
    const captionClass = classNames("caption", {
      secondary: this.props.subtitle
    });
    const selectClass = classNames("input-select-secondary", {
      disabled: this.props.disabled
    });

    return (
      <header className="artwork-header">
        <div>
          {this.props.description ? (
            <p
              className={captionClass}
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
          ) : null}
          {this.props.subtitle ? (
            <p
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: this.props.subtitle }}
            />
          ) : null}
        </div>

        <div className="summary">
          {total ? (
            <div className="summary">
              {`${this.numberWithCommas(total)} Works`}
            </div>
          ) : null}

          <div className="sort">
            <div className={selectClass}>
              <select
                disabled={this.props.disabled}
                value={this.props.sort}
                onChange={this.props.onSort}
                aria-label="Sort by"
              >
                {this.props.disabled ? (
                  <option disabled value="rel">
                    Relevance
                  </option>
                ) : null}
                <option value="">Newest</option>
                <option value="oldest">Oldest</option>
                <option value="az">A-Z</option>
                <option value="za">Z-A</option>
              </select>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
