const initialState = {
  added: [],
  available: [],
  active: null
};

export default function count(state = initialState, action) {
  const id = action.id ? action.id : null;
  const artwork = action.artwork ? action.artwork : null;

  switch (action.type) {
    case "ADD_AVAILABLE":
      // Keep an added array so artworks are only added once
      if (id && state.added.indexOf(id) === -1) {
        // Add the id to advailable
        return Object.assign({}, state, {
          added: state.added.concat([id]),
          available: state.available.concat([artwork])
        });
      } else {
        return state;
      }
    case "SET_ACTIVE":
      return Object.assign({}, state, { active: id });
    default:
      return state;
  }
}
