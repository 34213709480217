import dom from "../helpers/dom";
import refs from "../helpers/refs";

export default class DropdownClick {
  constructor(element) {
    // Setup globals
    this.el = element;
    this.openClass = "open";
    this.openState = false;

    const toggle = refs.get(element, "toggle");

    // Bind click toggles
    toggle.addEventListener("click", this.toggle);
  }

  toggle = event => {
    // Don't open links
    event.preventDefault();

    if (this.openState) {
      this.close(event);
    } else {
      this.open(event);
    }
  };

  open(event) {
    // Dont pass on this click to close
    event.stopPropagation();
    dom.addClass(this.el, this.openClass);
    // Use any subsequent click to close the drawer
    document.addEventListener("click", this.close);
    this.openState = true;
  }

  // Close is bound to the class so it can be called by document
  close = () => {
    dom.removeClass(this.el, this.openClass);
    this.openState = false;
    document.removeEventListener("click", this.close);
  };
}
