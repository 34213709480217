// Simple switch with on/off state for self
import dom from "../helpers/dom";

export default class Switch {
  constructor(element) {
    // Configure default attributes
    this.el = element;
    this.state = false;

    this.classOn = element.dataset.classOn
      ? element.dataset.classOn
      : "on";
    this.classOff = element.dataset.classOff
      ? element.dataset.classOff
      : "off";

    // Instantiate element as off unless a state is passed in
    this.state = element.dataset.state ? element.dataset.state : false;

    if (this.state) {
      // Change class/status to reflect initial state
      this.turnOn();
    } else {
      this.turnOff();
    }

    this.el.addEventListener("click", this.toggleElement);
  }

  turnOff() {
    this.state = false;
    dom.removeClass(this.el, this.classOn);
    dom.addClass(this.el, this.classOff);
  }

  turnOn() {
    this.state = true;
    dom.removeClass(this.el, this.classOff);
    dom.addClass(this.el, this.classOn);
  }

  toggleElement = () => {
    if (this.state) {
      // Element is 'on' so turn it off
      this.turnOff();
    } else {
      // Turn it on!
      this.turnOn();
    }
  };
}
