import fetch from "isomorphic-fetch";
import queryString from "query-string";

export default class ArtworkAPIClient {
  constructor() {
    this.paramMap = {};
    this.paramSwap = {
      sort: {
        oldest: {
          sort_field: "creation_year_end",
          sort_order: "asc"
        },
        az: {
          sort_field: "title",
          sort_order: "asc"
        },
        za: {
          sort_field: "title",
          sort_order: "desc"
        }
      }
    };
  }

  mapParameters(query) {
    // Take a query object (passed from React Router component)
    const mapped = {};
    const swapped = {};

    // Map query parameters based on API naming conventions
    // and return to React
    Object.keys(query).forEach(key => {
      // Use key directly from query string if it doesn't exist in the map
      const mappedParameter = this.paramMap[key] ? this.paramMap[key] : key;
      mapped[mappedParameter] = query[key];
    });

    Object.keys(mapped).forEach(key => {
      const options = this.paramSwap[key];

      if (options && options[mapped[key]]) {
        const option = options[mapped[key]];
        Object.keys(option).forEach(subKey => {
          swapped[subKey] = option[subKey];
        });
      } else {
        swapped[key] = mapped[key];
      }
    });

    return queryString.stringify(swapped);
  }

  fetchData(params, onSuccess, onError) {
    let url = "/api/v1/artworks";
    // Conditionally add params (as a string) to request
    url = params ? url + "?" + params : url;

    fetch(url)
      .then(response => {
        if (response.status !== 200) {
          // eslint-disable-next-line
          console.log(
            `API Request completed but status was ${response.status}`
          );
        } else {
          response.json().then(onSuccess);
        }
      })
      .catch(error => {
        onError(error);
      });
  }
}
