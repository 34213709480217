import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class InputResettable extends PureComponent {
  static displayName = "InputResettable";

  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func
  };

  constructor() {
    super();

    this.state = {
      input: ""
    };
  }

  componentDidMount() {
    // Set value from state (from props!)
    // if it's there
    if (this.props.value) {
      this.setState({
        input: this.props.value
      });
    }
  }

  componentWillReceiveProps(props) {
    // Set value from state (from props!)
    // if it has changed
    if (props.value && props.value !== this.props.value) {
      this.setState({
        input: props.value
      });
    }
  }

  handleInputChange = event => {
    this.setState({
      input: event.target.value
    });

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  resetInput = event => {
    // Reset input and submit form
    this.setState({
      input: ""
    });

    this.form.submit();
  };

  handleSubmit = event => {
    if (this.props.onSubmit) {
      event.preventDefault();
      this.props.onSubmit(event, this.state.input);
    }
  };

  render() {
    const input = this.state.input;
    const dirty = input.length > 0;

    const inputClass = classNames("text-input-trim", {
      dirty,
      filled: this.props.value
    });

    return (
      <form
        className={inputClass}
        onSubmit={this.handleSubmit}
        ref={f => {
          this.form = f;
        }}
      >
        <input
          type="text"
          value={this.state.input}
          placeholder={this.props.placeholder}
          onChange={this.handleInputChange}
        />
        <div className="trim" />
        {/* Hidden submit button */}
        <button className="submit" type="submit" />
        <button className="reset" onClick={this.resetInput}>
          {"reset"}
        </button>
      </form>
    );
  }
}
