import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class NoResults extends PureComponent {
  static displayName = "NoResults";

  static propTypes = {
    filters: PropTypes.object,
    itemTitle: PropTypes.string,
    clearRoute: PropTypes.string,
    filterSelections: PropTypes.object,
    search: PropTypes.string
  };

  constructor() {
    super();
    this.state = {
      filtersUsed: []
    };
  }

  componentDidMount() {
    this.checkFilters(this.props);
  }

  componentDidUpdate(nextProps) {
    if (this.props.filterSelections !== nextProps.filterSelections) {
      this.checkFilters(nextProps);
    }
  }

  checkFilters(props) {
    const { filters, filterSelections } = props;
    // If filters have been applied...
    if (filterSelections) {
      const filtersUsed = [];
      // Go through each selected filter...
      // TODO: Disabled until media is corrected
      // Object.keys(filterSelections).forEach(key => {
      //   if (filterSelections[key]) {
      //     // And match it with the full filtered object
      //     const tag = filters[key].find(filter => {
      //       return filter.key === parseInt(filterSelections[key]);
      //     });
      //
      //     if (tag) filtersUsed.push({ param: key, tag });
      //   }
      // });

      // Then finally, set a state with all the collected filter key/values
      this.setState({
        filtersUsed: [].concat(filtersUsed)
      });
    }
  }

  renderSuggestions(clear) {
    return (
      <div>
        <span className="subtitle">{"You might try..."}</span>
        <nav className="keywords">
          <ul>
            {this.state.filtersUsed.map(filter => {
              return (
                <li key={filter.tag.key}>
                  <a href={`/collection?${filter.param}=${filter.tag.key}`}>
                    {filter.tag.value}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
        <a className="reset-link" href={clear}>
          Or, start again
        </a>
      </div>
    );
  }

  render() {
    const clear = this.props.clearRoute ? this.props.clearRoute : "/";

    return (
      <div className="flash-notice">
        <header>
          <h3>
            {`There are no ${this.props.itemTitle} that match your search`}
          </h3>
        </header>
        {this.state.filtersUsed.length > 0 ? (
          this.renderSuggestions(clear)
        ) : (
          <a href={clear} className="reset-link">
            Start again
          </a>
        )}
      </div>
    );
  }
}
