import debounce from "lodash/debounce";
import dom from "../helpers/dom";

export default class SmoothAnchorTracker {
  constructor(el, props, afterActive) {
    this.onClass = "active";
    this.afterActive = afterActive || function(){};
    const anchors = el.querySelectorAll('a[href*="#"]');
    this.pairs = [];
    this.clicking = false;

    [...anchors].forEach(anchor => {
      const target = anchor.hash;
      const targetEl = el.querySelector(`a[name="${target.substring(1)}"]`);
      this.pairs.push({ anchor: anchor, target: targetEl });
      anchor.addEventListener("click", event => {
        this.clicking = true;
        this.toggleActive(anchor);
      });
    });

    this.toggleActive(this.getActiveAnchor());
    document.addEventListener("scroll", debounce(this.scrollHandler, 300));
  }

  scrollHandler = event => {
    if (this.clicking) {
      this.clicking = false;
    } else {
      this.toggleActive(this.getActiveAnchor());
    }
  };

  toggleActive(activeAnchor) {
    const parent = activeAnchor.parentElement;

    if (!dom.hasClass(parent, this.onClass)) {
      [...this.pairs].forEach(pair => {
        dom.removeClass(pair.anchor.parentElement, this.onClass);
      });

      dom.addClass(parent, this.onClass);

      this.afterActive(activeAnchor);
    }
  }

  getActiveAnchor() {
    let activePos = -10000000000;
    let activePair;

    [...this.pairs].forEach(pair => {
      if (pair.target) {
        const targetPos = pair.target.getBoundingClientRect().top;

        if (targetPos <= 0.8 * window.innerHeight && targetPos > activePos) {
          activePos = targetPos;
          activePair = pair;
        }
      }
    });

    return activePair ? activePair.anchor : this.pairs[0].anchor;
  }
}
