import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Flickity from "react-flickity-component/lib/index.js";
import get from "lodash/get";
import dom from "../helpers/dom";
import Artwork from "./Artwork";
import Slide from "./Slide";

export default class Slider extends PureComponent {
  static displayName = "Content.Slider";

  static propTypes = {
    items: PropTypes.array
  };

  componentDidMount() {
    if (this.slider) {
      this.bindClicks(this.slider.flkty);
    }
  }

  bindClicks(flickity) {
    const el = get(this.slider, "flkty.element");
    // Don't do anything fancy if flickity hasn't mounted
    if (!flickity || !el) return false;
    flickity.on("dragStart", event => {
      dom.addClass(el, "dragging");
    });

    flickity.on("dragEnd", event => {
      dom.removeClass(el, "dragging");
    });
  }

  render() {
    if (!this.props.items || this.props.items.length <= 0) {
      // eslint-disable-next-line
      console.log("Error: A slider failed to load");
      return false;
    }

    return (
      <Flickity
        ref={s => {
          this.slider = s;
        }}
        className="slider"
        options={{
          contain: true,
          lazyLoad: 4,
          prevNextButtons: false,
          freeScroll: true,
          pageDots: false,
          setGallerySize: false
        }}
      >
        {this.props.items.map((item, index) => {
          return item.artwork ? (
            <Artwork
              key={index}
              image={item.image}
              artwork={item.artwork}
              placeholder={item.placeholder}
              partial="Slide"
            />
          ) : (
            <Slide
              key={index}
              image={item.image}
              placeholder={item.placeholder}
            />
          );
        })}
      </Flickity>
    );
  }
}
