import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class List extends PureComponent {
  static displayName = "Content.TabList.List";

  static propTypes = {
    events: PropTypes.array
  };

  render() {
    return (
      <ul className="list">
        {this.props.events.map((event, index) => {
          return (
            <li key={index}>
              <a href={event.url} target="_blank" rel="noreferrer">
                <div
                  className="body"
                  dangerouslySetInnerHTML={{ __html: event.body }}
                />
                <span className="more">{event.more_text}</span>
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
}
