/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-backgroundblendmode-cssgrid_cssgridlegacy-touchevents-setclasses !*/
!(function(e, n, t) {
  function r(e, n) {
    return typeof e === n;
  }
  function o() {
    var e, n, t, o, s, i, a;
    for (var l in x)
      if (x.hasOwnProperty(l)) {
        if (
          ((e = []),
          (n = x[l]),
          n.name &&
            (e.push(n.name.toLowerCase()),
            n.options && n.options.aliases && n.options.aliases.length))
        )
          for (t = 0; t < n.options.aliases.length; t++)
            e.push(n.options.aliases[t].toLowerCase());
        for (o = r(n.fn, "function") ? n.fn() : n.fn, s = 0; s < e.length; s++)
          (i = e[s]),
            (a = i.split(".")),
            1 === a.length
              ? (Modernizr[a[0]] = o)
              : (!Modernizr[a[0]] ||
                  Modernizr[a[0]] instanceof Boolean ||
                  (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])),
                (Modernizr[a[0]][a[1]] = o)),
            C.push((o ? "" : "no-") + a.join("-"));
      }
  }
  function s(e) {
    var n = _.className,
      t = Modernizr._config.classPrefix || "";
    if ((w && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
      n = n.replace(r, "$1" + t + "js$2");
    }
    Modernizr._config.enableClasses &&
      ((n += " " + t + e.join(" " + t)),
      w ? (_.className.baseVal = n) : (_.className = n));
  }
  function i(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function(e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, "");
  }
  function a() {
    return "function" != typeof n.createElement
      ? n.createElement(arguments[0])
      : w
      ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0])
      : n.createElement.apply(n, arguments);
  }
  function l() {
    var e = n.body;
    return e || ((e = a(w ? "svg" : "body")), (e.fake = !0)), e;
  }
  function u(e, t, r, o) {
    var s,
      i,
      u,
      f,
      d = "modernizr",
      c = a("div"),
      p = l();
    if (parseInt(r, 10))
      for (; r--; )
        (u = a("div")), (u.id = o ? o[r] : d + (r + 1)), c.appendChild(u);
    return (
      (s = a("style")),
      (s.type = "text/css"),
      (s.id = "s" + d),
      (p.fake ? p : c).appendChild(s),
      p.appendChild(c),
      s.styleSheet
        ? (s.styleSheet.cssText = e)
        : s.appendChild(n.createTextNode(e)),
      (c.id = d),
      p.fake &&
        ((p.style.background = ""),
        (p.style.overflow = "hidden"),
        (f = _.style.overflow),
        (_.style.overflow = "hidden"),
        _.appendChild(p)),
      (i = t(c, e)),
      p.fake
        ? (p.parentNode.removeChild(p), (_.style.overflow = f), _.offsetHeight)
        : c.parentNode.removeChild(c),
      !!i
    );
  }
  function f(e, n) {
    return !!~("" + e).indexOf(n);
  }
  function d(e, n) {
    return function() {
      return e.apply(n, arguments);
    };
  }
  function c(e, n, t) {
    var o;
    for (var s in e)
      if (e[s] in n)
        return t === !1
          ? e[s]
          : ((o = n[e[s]]), r(o, "function") ? d(o, t || n) : o);
    return !1;
  }
  function p(e) {
    return e
      .replace(/([A-Z])/g, function(e, n) {
        return "-" + n.toLowerCase();
      })
      .replace(/^ms-/, "-ms-");
  }
  function m(n, t, r) {
    var o;
    if ("getComputedStyle" in e) {
      o = getComputedStyle.call(e, n, t);
      var s = e.console;
      if (null !== o) r && (o = o.getPropertyValue(r));
      else if (s) {
        var i = s.error ? "error" : "log";
        s[i].call(
          s,
          "getComputedStyle returning null, its possible modernizr test results are inaccurate"
        );
      }
    } else o = !t && n.currentStyle && n.currentStyle[r];
    return o;
  }
  function v(n, r) {
    var o = n.length;
    if ("CSS" in e && "supports" in e.CSS) {
      for (; o--; ) if (e.CSS.supports(p(n[o]), r)) return !0;
      return !1;
    }
    if ("CSSSupportsRule" in e) {
      for (var s = []; o--; ) s.push("(" + p(n[o]) + ":" + r + ")");
      return (
        (s = s.join(" or ")),
        u(
          "@supports (" + s + ") { #modernizr { position: absolute; } }",
          function(e) {
            return "absolute" == m(e, null, "position");
          }
        )
      );
    }
    return t;
  }
  function g(e, n, o, s) {
    function l() {
      d && (delete N.style, delete N.modElem);
    }
    if (((s = r(s, "undefined") ? !1 : s), !r(o, "undefined"))) {
      var u = v(e, o);
      if (!r(u, "undefined")) return u;
    }
    for (
      var d, c, p, m, g, h = ["modernizr", "tspan", "samp"];
      !N.style && h.length;

    )
      (d = !0), (N.modElem = a(h.shift())), (N.style = N.modElem.style);
    for (p = e.length, c = 0; p > c; c++)
      if (
        ((m = e[c]),
        (g = N.style[m]),
        f(m, "-") && (m = i(m)),
        N.style[m] !== t)
      ) {
        if (s || r(o, "undefined")) return l(), "pfx" == n ? m : !0;
        try {
          N.style[m] = o;
        } catch (y) {}
        if (N.style[m] != g) return l(), "pfx" == n ? m : !0;
      }
    return l(), !1;
  }
  function h(e, n, t, o, s) {
    var i = e.charAt(0).toUpperCase() + e.slice(1),
      a = (e + " " + P.join(i + " ") + i).split(" ");
    return r(n, "string") || r(n, "undefined")
      ? g(a, n, o, s)
      : ((a = (e + " " + j.join(i + " ") + i).split(" ")), c(a, n, t));
  }
  function y(e, n, r) {
    return h(e, t, t, n, r);
  }
  var C = [],
    x = [],
    S = {
      _version: "3.6.0",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function(e, n) {
        var t = this;
        setTimeout(function() {
          n(t[e]);
        }, 0);
      },
      addTest: function(e, n, t) {
        x.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function(e) {
        x.push({ name: null, fn: e });
      }
    },
    Modernizr = function() {};
  (Modernizr.prototype = S), (Modernizr = new Modernizr());
  var _ = n.documentElement,
    w = "svg" === _.nodeName.toLowerCase(),
    b = S._config.usePrefixes
      ? " -webkit- -moz- -o- -ms- ".split(" ")
      : ["", ""];
  S._prefixes = b;
  var T = (S.testStyles = u);
  Modernizr.addTest("touchevents", function() {
    var t;
    if ("ontouchstart" in e || (e.DocumentTouch && n instanceof DocumentTouch))
      t = !0;
    else {
      var r = [
        "@media (",
        b.join("touch-enabled),("),
        "heartz",
        ")",
        "{#modernizr{top:9px;position:absolute}}"
      ].join("");
      T(r, function(e) {
        t = 9 === e.offsetTop;
      });
    }
    return t;
  });
  var z = "Moz O ms Webkit",
    P = S._config.usePrefixes ? z.split(" ") : [];
  S._cssomPrefixes = P;
  var E = function(n) {
    var r,
      o = b.length,
      s = e.CSSRule;
    if ("undefined" == typeof s) return t;
    if (!n) return !1;
    if (
      ((n = n.replace(/^@/, "")),
      (r = n.replace(/-/g, "_").toUpperCase() + "_RULE"),
      r in s)
    )
      return "@" + n;
    for (var i = 0; o > i; i++) {
      var a = b[i],
        l = a.toUpperCase() + "_" + r;
      if (l in s) return "@-" + a.toLowerCase() + "-" + n;
    }
    return !1;
  };
  S.atRule = E;
  var j = S._config.usePrefixes ? z.toLowerCase().split(" ") : [];
  S._domPrefixes = j;
  var k = { elem: a("modernizr") };
  Modernizr._q.push(function() {
    delete k.elem;
  });
  var N = { style: k.elem.style };
  Modernizr._q.unshift(function() {
    delete N.style;
  }),
    (S.testAllProps = h);
  var L = (S.prefixed = function(e, n, t) {
    return 0 === e.indexOf("@")
      ? E(e)
      : (-1 != e.indexOf("-") && (e = i(e)), n ? h(e, n, t) : h(e, "pfx"));
  });
  Modernizr.addTest("backgroundblendmode", L("backgroundBlendMode", "text")),
    (S.testAllProps = y),
    Modernizr.addTest("cssgridlegacy", y("grid-columns", "10px", !0)),
    Modernizr.addTest("cssgrid", y("grid-template-rows", "none", !0)),
    o(),
    s(C),
    delete S.addTest,
    delete S.addAsyncTest;
  for (var A = 0; A < Modernizr._q.length; A++) Modernizr._q[A]();
  e.Modernizr = Modernizr;
})(window, document);
