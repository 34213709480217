import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Flickity from "flickity";

import Wrapper from "./Wrapper";

export default class ArtworkRouter extends PureComponent {
  static displayName = "Artwork.Router";

  static propTypes = {
    filters: PropTypes.object,
    sortable: PropTypes.bool,
    itemBootstrap: PropTypes.array,
    virtualCollectionId: PropTypes.number,
    meta: PropTypes.object,
    public: PropTypes.any,
    clearRoute: PropTypes.string,
    fetch: PropTypes.bool
  };

  static defaultProps = {
    sortable: true
  };

  showArtworkDetailCallback() {
    const flickityBox = document.getElementsByClassName("carousel");
    if (flickityBox.length > 0) {
      const flkty = new Flickity(".carousel");
      flkty.pausePlayer();
    }
  }

  hideArtworkDetailCallback() {
    const flickityBox = document.getElementsByClassName("carousel");
    if (flickityBox.length > 0) {
      const flkty = new Flickity(".carousel");
      flkty.unpausePlayer();
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/artwork/:artworkId?"
            render={props => (
              <Wrapper
                {...props}
                clearRoute="/collection"
                meta={this.props.meta}
                sortable={this.props.sortable}
                filters={this.props.filters}
                virtualCollectionId={this.props.virtualCollectionId}
                hideArtworkDetailCallback={this.hideArtworkDetailCallback}
                public={this.props.public}
              />
            )}
          />
          <Route
            path="/collection"
            render={props => (
              <Wrapper
                {...props}
                itemBootstrap={this.props.itemBootstrap}
                meta={this.props.meta}
                clearRoute="/collection"
                sortable={this.props.sortable}
                filters={this.props.filters}
              />
            )}
          />
          <Route
            path="/resources/public-collections"
            render={props => (
              <Wrapper
                {...props}
                itemBootstrap={this.props.itemBootstrap}
                itemTitle={"works in public collections"}
                meta={this.props.meta}
                clearRoute="/resources/public-collections"
                sortable={this.props.sortable}
                filters={this.props.filters}
                public={this.props.public}
              />
            )}
          />
          <Route
            path="/series/:seriesId"
            render={props => (
              <Wrapper
                {...props}
                clearRoute={this.props.clearRoute}
                itemBootstrap={this.props.itemBootstrap}
                sortable={this.props.sortable}
                virtualCollectionId={this.props.virtualCollectionId}
              />
            )}
          />
          <Route
            path="/resources/film/:film"
            render={props => (
              <Wrapper
                {...props}
                clearRoute={this.props.clearRoute}
                itemBootstrap={this.props.itemBootstrap}
                fetch={this.props.fetch}
                sortable={this.props.sortable}
              />
            )}
          />
          <Route
            path="/sketchbook/:sketchbook"
            render={props => (
              <Wrapper
                {...props}
                clearRoute={this.props.clearRoute}
                itemBootstrap={this.props.itemBootstrap}
                fetch={this.props.fetch}
                sortable={this.props.sortable}
                showArtworkDetailCallback={this.showArtworkDetailCallback}
                layoutsKey="basic"
              />
            )}
          />
        </Switch>
      </Router>
    );
  }
}
