import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import get from "lodash/get";
import debounce from "lodash/debounce";
import sortBy from "lodash/sortBy";
import classNames from "classnames";
import MaskedInput from "react-text-mask";

export default class ChronologyYearInput extends PureComponent {
  static displayName = "Chronology.YearInput";

  static propTypes = {
    years: PropTypes.array,
    focusOnLoad: PropTypes.bool,
    placeholder: PropTypes.string,
    previous: PropTypes.string,
    next: PropTypes.string,
    onUpdate: PropTypes.func,
    fixed: PropTypes.bool,
    kioskMode: PropTypes.object,
    arrowConfirm: PropTypes.bool
  };

  static defaultProps = {
    arrowConfirm: true
  };

  constructor() {
    super();

    this.state = {
      map: [],
      results: [],
      input: "",
      error: null,
      placeholderWidth: "auto",
      windowWidth: null
    };
  }

  componentDidMount() {
    this.mapYearsByName(this.props.years);
    this.debouncedPlaceholder = debounce(this.setWidthFromPlaceholder, 200);

    if (this.props.next || this.props.previous) {
      // Width of input can be set from a hidden "ruler"
      // element, so that pagination arrows can be positioned
      // flush to the element
      this.setWidthFromPlaceholder();

      window.setTimeout(() => {
        this.setWidthFromPlaceholder(true);
      }, 2000);

      // And again on resize
      window.addEventListener("resize", this.debouncedPlaceholder);
    }

    if (this.props.focusOnLoad) {
      const input = ReactDOM.findDOMNode(this.input);
      input.focus();
    }
  }

  componentDidUpdate(props, state) {
    if (this.props.years !== props.years) {
      this.mapYearsByName(props.years);
    }

    return null;
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.debouncedPlaceholder);
  }

  setWidthFromPlaceholder = force => {
    // Only set width if a resize has taken place
    // This mitigates an iOS bug that triggers this on scroll
    if (window.innerWidth !== this.state.windowWidth || force) {
      // Only set state if ruler can be found
      if (get(this.ruler, "offsetWidth")) {
        this.setState({
          placeholderWidth: this.ruler.offsetWidth + "px",
          windowWidth: window.innerWidth
        });
      }
    }
  };

  getMatches(raw, year) {
    const matches = [];

    this.state.map.forEach(c => {
      // Check if year contains raw input or
      // If extrapolated year fits between any of the chronologies
      if (
        c.begin.toString().indexOf(raw) > -1 ||
        c.end.toString().indexOf(raw) > -1 ||
        (year >= c.begin && year <= c.end)
      ) {
        matches.push(c);
      }
    });

    return matches;
  }

  handleKeyUp = event => {
    if (event.keyCode === 13) {
      this.handleSubmit(event);
    }
  };

  updateInput = event => {
    const onUpdate = this.props.onUpdate;

    // Remove error state on input
    // Get the input with trailing zeroes
    const inputYear = event ? event.target.value.replace(/\s/g, "0") : "";
    // Get the input raw number
    const inputRaw = event ? event.target.value.replace(/\s/g, "") : "";

    const results = this.getMatches(inputRaw, parseInt(inputYear));

    this.setState({
      error: null,
      input: inputRaw,
      results: [].concat(results)
    });

    if (onUpdate) onUpdate(inputRaw, results);
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({ input: "" });
    this.navigate();
  };

  navigate() {
    // Attempt to navigate!
    const { input, results, map } = this.state;
    let result;

    // There's a match!
    if (results && results.length === 1) result = results[0];

    // If there's no match, but...
    if (results.length <= 0 && input.length === 4) {
      if (input <= map[0].begin) {
        // If input is less than 1937, use the first available chronology
        result = map[0];
      } else if (input >= map[map.length - 1].begin) {
        // If it's greater than the most recent, use the lastest chronology
        result = map[map.length - 1];
      }
    }

    if (result) window.location.href = result.url;
  }

  mapYearsByName(years) {
    // Ensure "years" are sorted in ascending order
    const asc = sortBy(years, [
      y => {
        return parseInt(y.name);
      }
    ]);
    const map = [];

    // Push a listing to map with the beginning and end year for each
    // chronology "year"
    asc.forEach((c, index) => {
      // Set beginning and ending the same to start (most will be)
      const begin = parseInt(c.name);
      let end = parseInt(c.name);

      const next = asc[index + 1];
      if (next) end = parseInt(next.name) - 1;

      map.push({
        id: c.id,
        begin,
        end,
        url: c.url
      });
    });

    this.setState({ map: Object.assign([], map) });
  }

  maybeLinkWithJs = (event, url) => {
    if (get(this.props, "kioskMode.state") && this.props.kioskMode.state) {
      event.preventDefault();
      window.location.href = url;
    }
  };

  render() {
    const placeholder = this.props.placeholder;
    if (this.state.input) {
      this.placeholder = this.state.input;
    }
    const map = this.state.map;
    const width = this.state.placeholderWidth;

    const wrapperClass = classNames("year-input", {
      fixed: this.props.fixed,
      bold: placeholder,
      error: this.state.error,
      dirty: this.state.input,
      arrow: this.props.arrowConfirm,
      full: this.state.input.length === 4 || this.state.results.length === 1
    });
    return (
      <div className={wrapperClass}>
        {this.props.previous ? (
          <a
            onClick={e => {
              this.maybeLinkWithJs(e, this.props.previous);
            }}
            className="previous"
            href={this.props.previous}
          >
            <span className="screen-reader-text">
              Click to go to previous year
            </span>
          </a>
        ) : null}
        <form action="#" onSubmit={this.handleSubmit}>
          <div className="input-wrapper">
            <span className="screen-reader-text" id="yearInputLabel">
              Search by year
            </span>
            <MaskedInput
              className="placeholder"
              disabled
              type="text"
              mask={[/\d/, /\d/, /\d/, /\d/]}
              placeholderChar={"Y"}
              style={{ width }}
              value={this.state.input}
              aria-labelledby="yearInputLabel"
            />
            <MaskedInput
              ref={i => {
                this.input = i;
              }}
              className="active"
              type="text"
              mask={[/\d/, /\d/, /\d/, /\d/]}
              onKeyUp={this.handleKeyUp}
              onChange={this.updateInput}
              guide={false}
              placeholderChar={"Y"}
              placeholder={placeholder || "Type a Year"}
              autoComplete="off"
              style={{ width }}
              aria-labelledby="yearInputLabel"
            />
            <button type="submit" className="submit" aria-label="Submit" />
            <p className="label">
              {"1937-"}
              {map.length > 0
                ? map[map.length - 1].begin
                : new Date().getFullYear()}
            </p>
          </div>
        </form>
        <div
          className="ruler"
          ref={r => {
            this.ruler = r;
          }}
        >
          {placeholder}
        </div>
        {this.props.next ? (
          <a
            onClick={e => {
              this.maybeLinkWithJs(e, this.props.next);
            }}
            className="next"
            href={this.props.next}
          >
            <span className="screen-reader-text">Click to go to next year</span>
          </a>
        ) : null}
      </div>
    );
  }
}
