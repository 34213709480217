import dom from "../helpers/dom";
import throttle from "lodash/throttle";

export default class ScrollToFixed {
  constructor(el, min, thresholds) {
    this.min = min;
    this.thresholds = thresholds;

    // Save this once so it isn't used each time
    this.breakpoints = Object.keys(thresholds);

    // Check pinned once on load
    this.checkPinned(el);
    this.bindScroll(el);
  }

  getThreshold() {
    const BreakException = {};
    const matches = [];

    this.breakpoints.forEach(breakpoint => {
      if (window.innerWidth >= parseInt(breakpoint)) {
        matches.push(this.thresholds[breakpoint]);
      }
    });

    return matches.length > 0 ? Math.max(...matches) : this.min;
  }

  checkPinned(element) {
    // Get responsive threshold for pagination
    const threshold = this.getThreshold();
    const top = element.getBoundingClientRect().top;

    if (top <= threshold) {
      dom.addClass(element, "pinned");
    } else if (dom.hasClass(element, "pinned")) {
      dom.removeClass(element, "pinned");
    }
  }

  bindScroll(element) {
    const throttledCheck = throttle(() => {
      this.checkPinned(element);
    }, 50);

    window.addEventListener("scroll", throttledCheck);
  }
}
