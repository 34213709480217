import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class Slide extends PureComponent {
  static displayName = "Content.Slide";

  static propTypes = {
    image: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    altText: PropTypes.string
  };

  render() {
    return (
      <figure
        className="slide"
        onClick={this.props.onClick}
        role="button"
        tabIndex="0"
      >
        <div className="image">
          <img
            src={this.props.placeholder}
            data-flickity-lazyload={this.props.image}
            alt={this.props.altText}
          />
        </div>
      </figure>
    );
  }
}
