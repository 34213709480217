import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import queryString from "query-string";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import throttle from "lodash/throttle";
import classNames from "classnames";
import ScrollLocking from "../higherOrder/ScrollLocking";

import { ArtworkAPIClient } from "../api/";

import Header from "./Header";
import Form from "./Form";
import { ThumbnailList, ItemDetail } from "../shared";

export default class ArtworkWrapper extends PureComponent {
  static displayName = "Artwork.Wrapper";

  static propTypes = {
    filters: PropTypes.object,
    clearRoute: PropTypes.string,
    sortable: PropTypes.bool,
    itemBootstrap: PropTypes.array,
    fetch: PropTypes.bool,
    itemTitle: PropTypes.string,
    virtualCollectionId: PropTypes.number,
    public: PropTypes.bool,
    layoutsKey: PropTypes.string,
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    showArtworkDetailCallback: PropTypes.bool,
    hideArtworkDetailCallback: PropTypes.bool
  };

  static defaultProps = {
    sortable: true,
    fetch: true,
    itemTitle: "works in the Foundation Collection"
  };

  constructor(props) {
    super(props);

    // Props.location comes from router
    this.state = {
      filters: {},
      // Sorting comes from router too,
      sort: "",
      search: "",
      artworks: [],
      artworksIndexMap: [],
      total: null,
      activeItem: null,
      showActiveItem: false,
      collectionReturn: null,
      // Not sure if necessary yet
      response: null,
      loading: false,
      bootstrapLoaded: false,
      apiLoaded: false,
      nextMostPage: null
    };

    // Infinite scroll threshold from the bottom of the page
    this.ist = window.innerHeight;

    // Instantiate api client
    this.artworkAPIClient = new ArtworkAPIClient();

    this.paginateItem = this.paginateItem.bind(this);
    this.paginateItemPrev = this.paginateItemPrev.bind(this);
    this.paginateItemNext = this.paginateItemNext.bind(this);
    this.showArtworkDetail = this.showArtworkDetail.bind(this);
    this.hideArtworkDetail = this.hideArtworkDetail.bind(this);
    this.paginateOnScroll = this.paginateOnScroll.bind(this);
    this.sort = this.sort.bind(this);
  }

  componentDidMount() {
    // Before API is called, populate items with bootstrap data
    // if it's available
    if (this.props.itemBootstrap) {
      const artworks = this.props.itemBootstrap;
      this.setState(
        {
          artworks: [].concat(artworks)
        },
        () => {
          // Allow interacting with bootstrap data
          // only if fetch is disabled
          if (!this.props.fetch) {
            const artworksIndexMap = [];
            // Build artwork index map
            artworks.forEach((item, index) => {
              artworksIndexMap[item.artwork.id] = index;
            });

            this.setState({
              artworksIndexMap,
              bootstrapLoaded: true,
              // There can only ever be one page if there's no fetch
              totalPages: 1
            });
          }
        }
      );
    }

    // Optionally prevent fetching
    if (this.props.fetch) {
      this.fetchData(this.props.location, "init");

      this.throttledIS = throttle(this.paginateOnScroll, 600);
      window.addEventListener("scroll", this.throttledIS);
    }
    this.setStateFromProps(this.props);
    this.setStateFromRoute(this.props, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const props = this.props;

    const prevMatchId = prevProps.match.params.artworkId;
    const nextMatchId = props.match.params.artworkId;
    const nextLocation = props.location;
    const prevQuery = queryString.parse(prevProps.location.search);
    const nextQuery = queryString.parse(nextLocation.search);

    // Only do this if the route/params have changed
    if (!isEqual(prevQuery, nextQuery)) {
      this.setStateFromRoute(props);

      // Don't fetch data if transitioning between artwork detail
      if (!prevMatchId && !nextMatchId) {
        this.fetchData(nextLocation, this.getFetchAction(nextQuery));
      }
    }

    if (nextMatchId && nextMatchId !== prevMatchId) {
      // Otherwise, update the active item (and show detail or paginate)
      const activeItemIndex = prevState.artworksIndexMap[nextMatchId];

      this.setState({
        activeItem: prevState.artworks[activeItemIndex],
        showActiveItem: true
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledIS);
  }

  getFetchAction(next) {
    // If filters have changed, replace artwork
    let action = "replace";

    // If a page parameter exists, the filters are the same
    // and artwork is appended
    if (next && next.page) {
      action = "append";
    }

    return action;
  }

  fetch(params, action, onSuccess, onError) {
    this.artworkAPIClient.fetchData(
      params,
      results => {
        // Run default success method and callback
        this.handleFetchSuccess(results, action);
        if (onSuccess) onSuccess();
      },
      error => {
        // Run default success method and callback
        this.handleFetchError(error, onError);
        if (onError) onError();
      }
    );
  }

  getParams(location) {
    // Parameters get mapped based on a hash in the API Client
    // See api client to add/edit matching parameters
    const query = queryString.parse(location.search);

    // Merge collection override and query params
    const collectionOverride = this.props.virtualCollectionId
      ? { virtual_collection: this.props.virtualCollectionId }
      : {};

    const publicCollections = this.props.public ? { public: 1 } : {};

    // Set DHF or all collections depending on use
    const dhf = this.props.virtualCollectionId ? { dhf: 0 } : {};

    const merge = Object.assign(
      query,
      collectionOverride,
      publicCollections,
      dhf
    );
    return this.artworkAPIClient.mapParameters(merge);
  }

  // Note: Some parameter will need to be passed through to
  // branch whether the artworks are prepended, appended or replaced
  fetchData(location, action, onSuccess, onError) {
    this.setState({ loading: true });

    new Promise((resolve, reject) => {
      const params = this.getParams(location);
      resolve(params);
    }).then(params => {
      this.fetch(params, action, onSuccess, onError);
    });
  }

  handleFetchSuccess(results, action) {
    this.setState({ loading: false });
    // Make a new array of artwork data
    let artworks;
    const artworksIndexMap = [];

    // Either append or replace artwork
    switch (action) {
      case "append":
        artworks = this.state.artworks.concat(results.data);
        break;
      default:
        artworks = [].concat(results.data);
        break;
    }

    // Build artwork index map
    artworks.forEach((item, index) => {
      artworksIndexMap[item.artwork.id] = index;
    });

    const meta = results.meta;

    this.setState({
      artworks,
      artworksIndexMap,
      apiLoaded: true,
      // NB: The initial total shouldn't be calculated every time,
      // but leaving here until we get filtered total too
      total: meta.total,
      // Calculated every time in case filters change
      totalPages: meta.pagination.totalPages
    });
  }

  handleFetchError(error) {
    this.setState({ loading: false });
    throw error;
  }

  getMediaFromRoute(params) {
    // Populate media state with list of media
    if (!get(params, "media") || !this.allMedia || !this.mediaBySlug) {
      return false;
    }

    // Recursively iterate through all comma separated media
    // (and media group slugs)
    const addMixedMedia = (current, check, index) => {
      if (index >= check.length) {
        // Return completed array when done
        return current;
      }
      if (this.mediaBySlug[check[index]]) {
        // If media is a slug, run this function before continuing
        current = current.concat(
          addMixedMedia([], this.mediaBySlug[check[index]], 0)
        );
      } else if (
        current.indexOf(parseInt(check[index])) <= -1 &&
        this.allMedia.indexOf(parseInt(check[index])) > -1
      ) {
        // Otherwise, check if media is valid, not already used, and add it
        // Media exists and can be pushed to tracked media
        current.push(parseInt(check[index]));
      }
      // Otherwise we up the index and move on
      return addMixedMedia(current, check, index + 1);
    };

    const valid = [];
    // Get media ids or names from url
    const mediums = params.media.split(",");

    return addMixedMedia([], mediums, 0);
  }

  setStateFromProps(props) {
    const allMedia = [];
    const mediaBySlug = {};

    const media = get(props, "filters.media");
    if (media) {
      media.forEach(group => {
        // Save an array of all media
        group.media.forEach(m => {
          allMedia.push(m.id);
        });

        // Build a map of media groups by slug,
        // and populate them with an array of media ids
        mediaBySlug[group.slug] = group.media.map(m => {
          return m.id;
        });
      });
    }

    // Set component variable (won't change)
    this.allMedia = [].concat(allMedia);
    this.mediaBySlug = Object.assign({}, mediaBySlug);
  }

  // Set the component state (for UI)
  setStateFromRoute(props, init = false) {
    // NB: Filter UI is set from route for correct display, but this
    // doesn't effect the art that's loaded
    const query = queryString.parse(props.location.search);

    // Save the loaded page to pass down to lists
    if (init) {
      const beginQuery = query;
      this.loadedPage = parseInt(beginQuery.page);
      delete beginQuery.page;
      this.beginQuery = queryString.stringify(beginQuery);
    }

    // Populate year state with route
    // TODO: Update parameter logic to work with spreads
    const years = get(query, "years") ? query.years : null;
    const media = this.getMediaFromRoute(query);

    this.setState({
      filters: Object.assign({}, this.state.filters, {
        years,
        media: media ? [].concat(media) : null
      })
    });

    // Get search value
    const search = get(query, "search") ? query.search : "";

    // Get sort value, and override it if sorting is turned off, or
    // if there's a keyword search used
    let sort = get(query, "sort") ? query.sort : "newest";
    if (!this.props.sortable || search) sort = null;

    this.setState({
      search,
      sort
    });

    // Only set the pagination from route on init
    if (init) {
      this.setState({
        nextMostPage: init && get(query, "page") ? parseInt(query.page) : 1
      });
    }
  }

  isFirstArtwork() {
    const { activeItem, artworksIndexMap } = this.state;
    return artworksIndexMap[activeItem.artwork.id] === 0;
  }

  isLastArtwork() {
    const {
      artworks,
      activeItem,
      artworksIndexMap,
      nextMostPage,
      totalPages
    } = this.state;

    const last =
      artworksIndexMap[activeItem.artwork.id] >= artworks.length - 1 &&
      nextMostPage >= totalPages;
    return last;
  }

  paginateOnScroll() {
    // Don't do anything if pagination can't happen
    if (
      this.state.loading ||
      this.state.nextMostPage >= this.state.totalPages
    ) {
      return false;
    }

    // Otherwise, check proximity to bottom of page and load
    const bottom = window.pageYOffset + window.innerHeight;
    if (bottom + this.ist >= document.body.offsetHeight) {
      this.throttledIS.cancel();
      this.paginateNext();
    }
  }

  paginateNext(location = null, callback) {
    const nextMostPage = this.state.nextMostPage + 1;

    this.setState({ nextMostPage });
    this.paginate(nextMostPage, location, "append", callback);
  }

  paginate(page, storedRoute, action = null, callback = null) {
    // Updates the router with new page
    const { match, history } = this.props;

    // If a stored route is passed, paginate with that
    const location = storedRoute || this.props.location;
    // Conveniently returns an empty object even without a query
    const object = queryString.parse(location.search);
    const string = queryString.stringify(
      Object.assign(object, {
        page
      })
    );

    // Without a stored route, update the default history
    if (!storedRoute) {
      history.push(`${match.url}?${string}`);
    } else {
      // Otherwise, update the stored route and fetch new data
      storedRoute.search = "?" + string;

      this.setState({
        collectionReturn: storedRoute
      });

      this.fetchData(storedRoute, action, callback);
    }
  }

  showArtworkDetail(activeItem) {
    const { match, history, location } = this.props;

    // Set a collection return to come back to from current route,
    // including the match for subsequent page refreshes
    const collectionReturn = {
      path: match.url,
      match,
      search: location.search
    };

    this.setState({
      collectionReturn
    });

    history.push(`/artwork/${activeItem.artwork.id}`);

    if (this.props.showArtworkDetailCallback) {
      this.props.showArtworkDetailCallback();
    }
  }

  hideArtworkDetail() {
    this.setState({ showActiveItem: false });

    const { history } = this.props;
    const { collectionReturn } = this.state;

    const returnPath = collectionReturn.path + collectionReturn.search;

    setTimeout(function() {
      history.push(returnPath);
    }, 500);

    if (this.props.hideArtworkDetailCallback) {
      this.props.hideArtworkDetailCallback();
    }
  }

  paginateItemPrev() {
    const { activeItem, artworks } = this.state;

    if (artworks[0].index !== activeItem.index) {
      this.paginateItem(-1);
    }
  }

  paginateItemNext() {
    if (this.state.loading) return false;
    const { activeItem, artworks, nextMostPage, totalPages } = this.state;

    if (artworks[artworks.length - 1].index === activeItem.index) {
      // There are no more (loaded) items after this.
      if (nextMostPage < totalPages) {
        // NB: Violating this shouldn't be possible if buttons are hidden,
        // but just in case
        // eslint-disable-next-line
        console.log('End of a "page" loading more...');
        // Paginate next without behind the scenes, and then move forward in
        // a callback
        this.paginateNext(this.state.collectionReturn, () => {
          this.paginateItem(1);
        });
      }
    } else {
      this.paginateItem(1);
    }
  }

  paginateItem(direction) {
    // Setup required variables
    const { artworksIndexMap, artworks, activeItem } = this.state;
    const { history } = this.props;

    const newActiveIndex = artworksIndexMap[activeItem.artwork.id] + direction;

    const newActiveItem = artworks[newActiveIndex];

    // Prevent error on rapid clicking by only pushing if item is defined
    if (newActiveItem) {
      history.push(`/artwork/${artworks[newActiveIndex].artwork.id}`);
    }
  }

  sort(event) {
    const { history, match, location } = this.props;

    // Use sort param or not
    const sort = event.target.value.length > 0 ? event.target.value : null;

    // Build new search string adding sorting fields
    const prevSearch = queryString.parse(location.search);
    // Delete page and sorting fields to be replaced if they exist
    delete prevSearch.page;
    delete prevSearch.sort;

    // Clone prevSearch or add sort
    const search = sort
      ? Object.assign(prevSearch, { sort })
      : Object.assign({}, prevSearch);

    history.push(`${match.path}/?${queryString.stringify(search)}`);
  }

  showNoResults() {
    let output = false;
    if (this.props.fetch) {
      output = !this.state.loading && this.state.artworks.length <= 0;
    }

    return output;
  }

  render() {
    const { match } = this.props;

    const promptClass = classNames("scroll-prompt", {
      disabled: this.state.loading,
      hidden:
        this.state.nextMostPage >= this.state.totalPages || !this.props.fetch
    });

    const listClass = classNames("thumbnail-list", {
      "prevent-click": !this.state.bootstrapLoaded && !this.state.apiLoaded
    });

    return (
      <div>
        {this.props.sortable ? (
          <Header
            total={this.state.total}
            description={get(this.props, "meta.description")}
            subtitle={get(this.props, "meta.subtitle")}
            sort={this.state.search ? "rel" : this.state.sort}
            disabled={!!this.state.search}
            onSort={this.sort}
          />
        ) : null}
        <div className={listClass}>
          <ThumbnailList
            items={this.state.artworks}
            onLink={this.showArtworkDetail}
            clearRoute={this.props.clearRoute}
            beginQuery={this.beginQuery}
            itemTitle={this.props.itemTitle}
            sort={this.state.sort}
            pageStart={this.loadedPage}
            form={Form}
            formFilters={this.props.filters}
            filterSelections={this.state.filters}
            formSearch={this.state.search}
            formFooter={get(this.props, "meta.form")}
            noResults={this.showNoResults()}
            layoutsKey={this.props.layoutsKey}
          />
          {this.state.loading ? (
            <div className="loader-bar">
              <div className="progress" />
            </div>
          ) : null}
          <div className={promptClass}>
            Scroll down
            <br />
            to see more
          </div>
        </div>
        <CSSTransition
          in={this.state.showActiveItem}
          timeout={1000}
          classNames={"fade"}
        >
          <div>
            {match.params.artworkId && this.state.activeItem ? (
              <ScrollLocking>
                <ItemDetail
                  item={this.state.activeItem.artwork}
                  first={this.isFirstArtwork()}
                  last={this.isLastArtwork()}
                  loading={this.state.loading}
                  paginatePrev={this.paginateItemPrev}
                  paginateNext={this.paginateItemNext}
                  closeCallback={this.hideArtworkDetail}
                />
              </ScrollLocking>
            ) : null}
          </div>
        </CSSTransition>
      </div>
    );
  }
}
