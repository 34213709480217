import throttle from "lodash/throttle";
import refs from "../helpers/refs";

import SmoothAnchorTracker from "./SmoothAnchorTracker";
import ScrollToFixed from "../shared/ScrollToFixed";

export default class AnchorNavFixed {
  constructor(el) {
    this.active = null;
    this.marker = refs.get(el, "nav-marker");

    // Make element fixed on scroll
    const fixer = new ScrollToFixed(el, 37, {
      "1370": 144,
      "960": 91,
      "760": 81
    });

    // Setup a smooth anchor tracker, and pass the active the active
    // anchor when it's set
    const tracker = new SmoothAnchorTracker(el, {}, anchor => {
      this.active = anchor;
      this.setMarker();
    });

    const throttledSet = throttle(() => {
      this.setMarker();
    }, 200);

    window.addEventListener("resize", throttledSet);
  }

  setMarker() {
    const bb = this.active.getBoundingClientRect();
    // Set opacity to 1 in case it was hidden before
    this.marker.style.width = bb.width + "px";
    this.marker.style.left = bb.x + "px";
    this.marker.style.opacity = 1;
  }
}
