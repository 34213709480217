import Cookies from "js-cookie";
// import dom from "../helpers/dom";
import get from "lodash/get";
import startsWith from "lodash/startsWith";

export default class KioskAware {
  constructor(el, props) {
    // Only check for kiosk if section is set
    if (get(props, "section")) {
      const section = props.section;
      const path = window.location.pathname;
      this.cooKey = `kiosk_${section}`;

      if (Cookies.get(this.cooKey)) {
        // If cookie is enabled, check current route and
        // redirect if not inside the section
        if (
          !startsWith(path, `/${section}`) &&
          path !== `/kiosk-settings-${section}`
        ) {
          window.location.href = `/${section}`;
        }
      }
    }
  }
}
