import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class ChronologyYearListing extends PureComponent {
  static displayName = "Chronology.YearListing";

  static propTypes = {
    year: PropTypes.object,
    onHover: PropTypes.func,
    jsLink: PropTypes.bool
  };

  componentDidMount() {
    // Bind hover behavior here
    this.el.addEventListener("mouseenter", this.handleHover);
  }

  componentWillUnmount() {
    this.el.removeEventListener("mouseenter", this.handleHover);
  }

  handleHover = event => {
    // Pass year id to callback function
    if (this.props.onHover) {
      this.props.onHover(this.props.year.id);
    }
  };

  // Link with JS if set to
  maybeLinkWithJs = (event, url) => {
    if (this.props.jsLink) {
      event.preventDefault();
      window.location.href = url;
    }
  };

  yearDisplayName(year) {
    return year.override ? year.override : year.name;
  }

  render() {
    const year = this.props.year;
    return (
      <li
        ref={el => {
          this.el = el;
        }}
      >
        <a
          onClick={e => {
            this.maybeLinkWithJs(e, year.url);
          }}
          href={year.url}
        >
          <div className="year">
            <span>{this.yearDisplayName(year)}</span>
          </div>
          <div
            className="caption"
            dangerouslySetInnerHTML={{ __html: year.teaser }}
          />
          <div className="touch-prompt">{"Tap again to view full year"}</div>
        </a>
      </li>
    );
  }
}
