import dom from "../helpers/dom";

export default class SharedHover {
  constructor(el) {
    this.hoverClass = "hover";
    const shareData = el.dataset.share;
    if (shareData) {
      // Bind hovers
      this.bindHover(el, this.getShareEls(el, shareData));
    } else {
      const error =
        "Error: Shared hover requires a data-share attribute with " +
        "a comma separated list of selectors";
      // eslint-disable-next-line
      console.log(error);
    }
  }

  getShareEls(el, data) {
    let els = [];
    const shares = data.split(",");
    shares.forEach(selector => {
      els = els.concat([...el.parentNode.querySelectorAll(selector)]);
    });

    return els;
  }

  bindHover(el, targets) {
    el.addEventListener("mouseenter", event => {
      targets.forEach(target => {
        dom.addClass(target, this.hoverClass);
      });
    });

    el.addEventListener("mouseleave", event => {
      targets.forEach(target => {
        dom.removeClass(target, this.hoverClass);
      });
    });
  }
}
