import dom from "../helpers/dom";
import refs from "../helpers/refs";
import debounce from "lodash/debounce";
import ScrollLock from "../shared/ScrollLock";
import FocusTrap from "../shared/FocusTrap";

export default class Hamburger {
  constructor(element) {
    this.el = element;
    this.refs = refs.all(element);
    this.searchBreak = 760;

    this.lock = "null";

    this.scrollLock = new ScrollLock();
    this.focusTrap = new FocusTrap({
      onEscape: this.handleEscape
    });

    this.classes = {
      hamburger: "hamburger-open",
      search: "search-open",
      dropdown: "dropdown-open"
    };

    // Bind methods called from events
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);

    // Assign click handlers
    const toggleHam = this.refs.toggleHamburger;
    const closeHam = this.refs.closeHamburger;
    const toggleSearch = this.refs.toggleSearch;
    const closeSearch = this.refs.closeSearch;
    const searchInput = this.refs.searchInput;
    const dropdownToggles = element.querySelectorAll("[data-dropdown-toggle]");

    // Setup debounched lock handler
    this.debouncedSearchLock = debounce(this.handleLockSearch, 150);

    if (toggleHam) {
      toggleHam.addEventListener("click", () => {
        this.toggle("hamburger", {
          on: () => {
            this.lockHam();
            this.trapHam();
          },
          off: () => {
            this.lockHam(false);
            this.trapHam(false);
          }
        });
      });
    }

    if (closeHam) {
      closeHam.addEventListener("click", () => {
        // Close hamburger and unlock
        this.close("hamburger", null, () => {
          this.lockHam(false);
          this.focusTrap.untrap(this.el);
        });
      });
    }

    [...dropdownToggles].forEach(toggle => {
      toggle.addEventListener("mouseenter", () => {
        this.open("dropdown", toggle.dataset.dropdownToggle);
      });
    });

    [...dropdownToggles].forEach(toggle => {
      toggle.addEventListener("mouseleave", () => {
        this.close("dropdown", toggle.dataset.dropdownToggle);
      });
    });

    if (toggleSearch) {
      toggleSearch.addEventListener("click", () => {
        this.toggle("search", {
          on: () => {
            this.handleLockSearch();
            window.addEventListener("resize", this.debouncedSearchLock);
            this.focusTrap.trap(this.el.querySelector("[data-search-overlay]"));
          },
          off: () => {
            this.lockSearch(false);
            window.removeEventListener("resize", this.debouncedSearchLock);
            this.focusTrap.untrap(
              this.el.querySelector("[data-search-overlay]")
            );
          }
        });
      });
    }

    if (closeSearch) {
      closeSearch.addEventListener("click", () => {
        this.close("search", null, () => {
          this.lockSearch(false);
          window.removeEventListener("resize", this.debouncedSearchLock);
        });
        this.focusTrap.untrap(this.el.querySelector("[data-search-overlay]"));
      });
    }
  }

  handleLockSearch = () => {
    const lock = window.innerWidth <= this.searchBreak;
    this.lockSearch(lock);
  };

  lockSearch = (lock = true) => {
    if (lock) {
      // Unlock scroll for hamburger hamburger
      if (this.lock === "hamburger") {
        this.lockHam(false);
      }

      this.scrollLock.lock(
        this.el.querySelector("[data-search-overlay]"),
        "hide-overflow-y"
      );

      this.lock = "search";
    } else if (this.lock === "search") {
      // Unlock search
      this.scrollLock.unlock(
        this.el.querySelector("[data-search-overlay]"),
        "hide-overflow-y"
      );

      this.lock = null;
    }
  };

  lockHam = (lock = true) => {
    const listEl = this.el.querySelector("[data-list-primary]");
    if (lock) {
      // Lock scroll for hamburger
      this.lock = "hamburger";

      this.scrollLock.lock(listEl, "hide-overflow-y");
    } else {
      // Unlcok scroll for hamburger
      this.lock = null;

      this.scrollLock.unlock(listEl, "hide-overflow-y");
    }
  };

  trapHam = (trap = true) => {
    const listEl = this.el.querySelector("[data-list-primary]");
    if (trap) {
      this.focusTrap.trap(listEl, this.el);
    } else {
      this.focusTrap.untrap(listEl, this.el);
    }
  };

  handleEscape = el => {
    if (el.dataset.searchOverlay !== undefined) {
      this.close("search");
    } else if (el.dataset.listPrimary !== undefined) {
      this.close("hamburger");
    }
  };

  open(el, id, cb = () => {}) {
    Object.keys(this.classes).forEach(el => {
      this.close(el);
    });

    // Conditionally add an id to the class if it exists
    const add = id ? this.classes[el] + `-${id}` : this.classes[el];

    dom.addClass(this.el, add);

    if (el === "search") {
      this.refs.searchInput.focus();
    }

    cb();
  }

  close(el, id, cb = () => {}) {
    // Conditionally add an id to the class if it exists
    const less = id ? this.classes[el] + `-${id}` : this.classes[el];

    dom.removeClass(this.el, less);

    cb();
  }

  toggle(el, cb = { on: () => {}, off: () => {} }) {
    if (dom.hasClass(this.el, this.classes[el])) {
      this.close(el, null, cb.off);
    } else {
      this.open(el, null, cb.on);
    }
  }
}
