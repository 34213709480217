import debounce from "lodash/debounce";
import refs from "../helpers/refs";

export default class Callout {
  constructor(element) {
    this.element = element;
    this.callout = refs.get(element, "callout");
    this.breakpoint = 640;

    // Run positioning on load
    this.repositionOnscreen();

    // Run on debounced resize
    const debounced = debounce(this.repositionOnscreen, 200);
    window.addEventListener("resize", debounced);
  }

  repositionOnscreen = () => {
    // Check this once from the browser per resize
    const windowWidth = window.innerWidth;

    // Remove existing margin it it has one and check dimensions
    const element = this.element;
    if (element.style.marginRight) element.style.removeProperty("margin-right");
    if (element.style.marginLeft) element.style.removeProperty("margin-left");

    // After removing margins, don't do anything at narrow breakpoints
    if (windowWidth < this.breakpoint) return false;

    // Check dimensions without margins
    const box = this.callout.getBoundingClientRect();

    const offsetLeft = box.left - 30;
    const offsetRight = box.left + box.width + 30 - windowWidth;

    if (offsetLeft <= 0) {
      element.style.marginRight = `-${Math.abs(offsetLeft)}px`;
    } else if (offsetRight >= 0) {
      element.style.marginLeft = `-${Math.abs(offsetRight)}px`;
    }
  };
}
