import get from "lodash/get";

export default class InjectNested {
  constructor(element, props) {
    // Setup default attributes

    const nested = element.querySelector("[data-nested]");
    const shortcode = get(props, "shortcode") ? props.shortcode : "[nested]";
    this.break = 960;

    // Only continue if there is an element to nest
    if (!nested) return false;
    this.parseAndInject(element, nested, shortcode);
  }

  responsiveReplace(p) {
    // Ensure markup varients exist on class first
    if (this.stacked && this.nested) {
      if (window.innerWidth >= this.break) {
        // Wider windows, use nested markup
        p.innerHTML = this.nested;
      } else {
        // Narrower windows, use stacked markup
        p.innerHTML = this.stacked;
      }
    }
  }

  splitAndReplace(nest, sc, p) {
    const original = p.innerHTML;
    // Get new sc index inside innerHTML and replace
    const scIndex = p.innerHTML.indexOf(sc);

    const before = original.substr(0, scIndex);
    const after = original.substr(scIndex + sc.length);
    return before + `</p>${nest.innerHTML}<p>` + after;
  }

  injectNested(nest, sc, p) {
    if (p.innerHTML.length === sc.length) {
      // Quote is alone in a p tag
      // replace tag with nested
      // Don't worry about mobile in this case
      p.parentNode.replaceChild(nest, p);
    } else {
      // Remove nested from it's original location,
      // and insert into paragraph HTML
      nest.parentNode.removeChild(nest);

      const stacked = p.innerHTML.replace(sc, "");
      const nested = this.splitAndReplace(nest, sc, p);

      const stackedEl = document.createElement("div");
      stackedEl.className = "content-nested stacked";
      stackedEl.innerHTML = stacked;

      const nestedEl = document.createElement("div");
      nestedEl.className = "content-nested nested";
      nestedEl.innerHTML = nested;

      p.parentNode.appendChild(stackedEl);
      p.parentNode.appendChild(nestedEl);
      p.parentNode.removeChild(p);
    }
  }

  parseAndInject(el, nest, sc) {
    const pars = [...el.getElementsByTagName("p")];

    // Iterate over each paragraph and replace
    // shortcode with nesteds if they exist
    let found = false;

    pars.forEach(p => {
      const scIndex = p.innerHTML.indexOf(sc);
      if (scIndex > -1 && !found) {
        // Prevent further injections
        found = true;
        this.injectNested(nest, sc, p);
      }
    });
  }
}
