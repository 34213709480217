import queryString from "query-string";

export default class QueryForm {
  constructor(form) {
    this.inputs = [...form.querySelectorAll('[data-input="input"]')];

    // Bind select behavior
    const selects = [...form.querySelectorAll('[data-input="select"]')];
    selects.forEach(this.bindSelectBehavior);

    const resets = [...form.querySelectorAll("[data-reset]")];
    resets.forEach(this.bindResetBehavior);

    // Bind submit behavior
    form.addEventListener("submit", event => {
      event.preventDefault();
      const [inputs, resets] = this.getInputValues();
      this.sendQuery(inputs, resets);
    });
  }

  sendQuery(payload, resets) {
    const location = window.location;
    const url = queryString.parse(location.search);

    // Remove params that are being reset from existing url
    if (resets) {
      resets.forEach(param => {
        delete url[param];
      });
    }

    const next = Object.assign({}, url, payload);

    let nextString = queryString.stringify(next);
    nextString = nextString.length > 0 ? "?" + nextString : "";

    location.href =
      `${location.protocol}//` +
      `${location.host}${location.pathname}${nextString}`;
  }

  getInputValues() {
    // Outputs an array of values and resets, which
    // is destructured into a payload/reset object
    const output = {};
    const resets = [];
    this.inputs.forEach(input => {
      if (input.value) {
        output[input.name] = input.value;

        // If search has changed, always reset the page
        if (input.name === "search" && input.value !== input.dataset.current) {
          resets.push("page");
        }
      } else {
        resets.push(input.name);
      }
    });

    return [output, resets];
  }

  bindSelectBehavior = select => {
    select.addEventListener("change", () => {
      if (select.value) {
        // On change, update the query with the name/value
        this.sendQuery(
          {
            [select.name]: select.value
          },
          ["page"]
        );
      } else {
        // If there's no value, reset it instead
        this.sendQuery({}, [select.name]);
      }
    });
  };

  bindResetBehavior = button => {
    button.addEventListener("click", event => {
      event.preventDefault();
      const input = button.dataset.reset;
      if (input) {
        this.sendQuery({}, [input, "page"]);
      }
    });
  };
}
