import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import Content from "./index";

class Artwork extends PureComponent {
  static displayName = "Content.Artwork";

  static propTypes = {
    image: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    artwork: PropTypes.object.isRequired,
    caption: PropTypes.string,
    partial: PropTypes.string,
    dispatch: PropTypes.func,
  };

  static defaultProps = {
    partial: "CaptionImage",
  };

  static mapStateToProps(state) {
    return {};
  }

  constructor(props) {
    super();
    this.addAvailable(props.dispatch, props.artwork);
  }

  addAvailable(dispatch, artwork) {
    const id = artwork.id;
    dispatch({ type: "ADD_AVAILABLE", id, artwork });
  }

  handleSetActive = (event) => {
    event.preventDefault();
    const id = this.props.artwork.id;
    this.props.dispatch({ type: "SET_ACTIVE", id });
  };

  render() {
    const artwork = this.props.artwork;
    const Component = Content[this.props.partial];

    return (
      <Component
        image={this.props.image}
        width={artwork.width}
        height={artwork.height}
        placeholder={this.props.placeholder}
        linked={true}
        caption={artwork.title_comma_year}
        onClick={this.handleSetActive}
        altText={get(artwork, "image.description") || ""}
      />
    );
  }
}

export default connect(Artwork.mapStateToProps)(Artwork);
