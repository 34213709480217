import "core-js/stable";
import "focus-visible";
import "focus-within-polyfill";
import "./style/stylesheets/styles.scss";
import "./js/lib/modernizr.min.js";
import { createStore } from "redux";
import ComponentLoader from "./js/Loader";
import reducers from "./js/reducers";

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const componentLoader = new ComponentLoader(store);
