import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

export default class KioskAware extends PureComponent {
  static displayName = "HigherOrder.KioskAware";

  static propTypes = {
    section: PropTypes.string,
    children: PropTypes.node
  };

  constructor() {
    super();

    this.state = {
      kioskMode: {
        state: false,
        section: null
      }
    };
  }

  componentDidMount() {
    this.checkKioskMode();
  }

  checkKioskMode() {
    if (!this.props.section) {
      this.setState({
        kioskMode: Object.assign({}, { state: false })
      });
    } else {
      const cooKey = `kiosk_${this.props.section}`;
      const state = !!Cookies.get(cooKey);

      this.setState({
        kioskMode: Object.assign(
          {},
          {
            state,
            section: this.props.section
          }
        )
      });
    }
  }

  render() {
    const { children } = this.props;

    return React.Children.map(children, child =>
      React.cloneElement(child, { kioskMode: this.state.kioskMode })
    );
  }
}
