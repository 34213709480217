import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import get from "lodash/get";

export default class ArtworMeta extends PureComponent {
  static displayName = "Artwork.Meta";

  static propTypes = {
    item: PropTypes.object,
    kioskMode: PropTypes.object
  };

  getTags() {
    const { item } = this.props;
    const tags = [];
    tags.push({
      param: "media",
      tags: item.media
    });
    tags.push({
      param: "years",
      tags: [
        {
          key: item.creation_year_range_filter,
          value: item.creation_year_range_label
        }
      ]
    });
    return tags;
  }

  getSeries() {
    const { item } = this.props;
    const series = [];
    return [];
  }

  renderTags() {
    const attributes = this.getTags();
    const collectionBaseUrl = this.props.item.collection_type_base_url;
    return attributes.map(attribute => {
      return attribute.tags.map(tag => {
        const ref = `/${collectionBaseUrl}?${attribute.param}=${tag.key}`;
        return (
          <li key={tag.key}>
            <a href={ref}>{tag.value}</a>
          </li>
        );
      });
    });
  }

  renderSeriesPortfolios() {
    const series = this.props.item.series;
    return series.map(s => {
      const ref = `/series/${s.slug}`;
      return (
        <li key={s.id}>
          <a href={ref}>{s.name}</a>
        </li>
      );
    });
  }

  render() {
    const item = this.props.item;
    const series = this.getSeries();

    const disableLinks =
      get(this.props, "kioskMode.state") && this.props.kioskMode.state;

    const showRelatedSearches = item.collection_type !== "private";

    return (
      <div className="details">
        <div className="title">
          <span
            dangerouslySetInnerHTML={{
              __html: item.title
            }}
          />
          <span className="year">
            ,&nbsp;{item.creation_year_range_label}
          </span>
        </div>
        <div className="meta">
          <div className="info">
            <ul>
              <li>
                <span className="label">{"Medium"}</span>
                <p>
                  {item.medium_text}
                  {item.edition_text ? ", " + item.edition_text : ""}
                </p>
              </li>
              {item.dimensions_text ? (
                <li>
                  <span className="label">{"Dimensions"}</span>
                  <p>{item.dimensions_text}</p>
                </li>
              ) : null}
              {item.duration ? (
                <li>
                  <span className="label">{"Duration"}</span>
                  <p>{item.duration}</p>
                </li>
              ) : null}
              {!disableLinks && showRelatedSearches ? (
                <li>
                  <span className="label">{"Collection"}</span>
                  <p>
                    {item.collection_url ? (
                      <a
                        href={item.collection_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.collection_name}
                      </a>
                    ) : (
                      item.collection_name
                    )}
                  </p>
                </li>
              ) : null}
            </ul>
          </div>
          {!disableLinks && showRelatedSearches ? (
            <div className="related">
              <div className="tags">
                <h4 className="label">Related Searches</h4>
                <ul>{this.renderTags()}</ul>
              </div>
              {item.series && item.series.length > 0 ? (
                <div className="series">
                  <h4 className="label">Portfolio/Series</h4>
                  <ul>{this.renderSeriesPortfolios()}</ul>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="info">
              <ul>
                <li>
                  <span className="label">{"Collection"}</span>
                  <p>{item.collection_name}</p>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}
