import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { Swipeable } from "react-swipeable";
import List from "./List";

export default class TabList extends PureComponent {
  static displayName = "Content.TabList.Wrapper";

  static propTypes = {
    events: PropTypes.array.isRequired
  };

  constructor() {
    super();

    this.state = {
      active: 1,
      markerWidth: "0px",
      markerLeft: "0px"
    };
  }

  componentDidMount() {
    this.buildMarker();

    this.debouncedMarker = debounce(this.buildMarker, 200);
    window.addEventListener("resize", this.debouncedMarker);
  }

  componentDidUpdate(prevProps, prevState) {
    // Move marker if active state changed
    if (prevState.active !== this.state.active) {
      this.buildMarker();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedMarker);
  }

  setActive(id) {
    this.setState({
      active: id
    });
  }

  activeNext = () => {
    // Only paginate if there's a page available
    const active = this.state.active;
    if (active < this.props.events.length) {
      this.setActive(active + 1);
    }
  };

  activePrev = () => {
    // Only paginate if there's a page available
    const active = this.state.active;
    if (active > 1) {
      this.setActive(active - 1);
    }
  };

  buildMarker = () => {
    const b = this["b" + this.state.active];
    const parentLeft = b.parentNode.getBoundingClientRect().left;
    const markerWidth = b.offsetWidth + "px";
    const rect = b.getBoundingClientRect();
    const markerLeft = rect.left - parentLeft + "px";

    this.setState({
      markerWidth,
      markerLeft
    });
  };

  render() {
    const events = this.props.events;
    const active = this.state.active;

    return (
      <Swipeable
        className="tab-list"
        trackMouse={true}
        onSwipingLeft={event => {
          event.stopPropagation();
        }}
        onSwipingRight={event => {
          event.stopPropagation();
        }}
        onSwipedLeft={this.activeNext}
        onSwipedRight={this.activePrev}
      >
        <div className="nav">
          <ul>
            {events.map((group, index) => {
              return (
                <li
                  key={index}
                  className={index + 1 === active ? "active" : null}
                  ref={b => {
                    this["b" + (index + 1)] = b;
                  }}
                >
                  <button
                    onClick={() => {
                      this.setActive(index + 1);
                    }}
                  >
                    {group.title}
                  </button>
                </li>
              );
            })}
          </ul>
          <div
            className="nav-marker"
            style={{
              width: this.state.markerWidth,
              left: this.state.markerLeft
            }}
          />
        </div>
        <div className="wrapper">
          <ul
            className="panes"
            style={{
              width: events.length * 100 + "%",
              transform: `translateX(-${100 - 100 / active}%)`
            }}
          >
            {events.map((group, index) => {
              return (
                <li key={index}>
                  {group.events && group.events.length > 0 ? (
                    <List events={group.events} />
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
      </Swipeable>
    );
  }
}
