import get from "lodash/get";

// Pagination input class
// Controls both scrolling lockup and some click events
import refs from "../helpers/refs";
import ScrollToFixed from "../shared/ScrollToFixed";

export default class PaginationInput {
  constructor(element, props) {
    const ordinals = refs.get(element, "ordinals");
    const underline = refs.get(element, "underline");

    const digits = get(props, "digits") ? props.digits : 1;

    // Make element fixed on scroll
    const fixer = new ScrollToFixed(element, 31, {
      "1370": 101,
      "960": 89,
      "760": 81,
      "475": 33
    });

    const input = ordinals.querySelector("input");
    this.setInputSize(input, underline, digits);
    this.bindClicks(ordinals, input);
  }

  setInputSize(input, underline, digits) {
    input.style.width = 0.775 * digits + "em";
    underline.style.width = 0.55 * digits + "em";
  }

  bindClicks(target, input) {
    target.addEventListener("click", () => {
      input.focus();
    });
  }
}
